import React from 'react';

import HeaderGoat from '../components/ui/HeaderGoat.js';
import HomeFoto from '../components/home/section/FotoHead.js';
// import HomeVideo from '../components/home/Video.js';
import HomeGoatFromDay0 from '../components/home/section/GoatFromDay0.js';
import HomeGoatNation from '../components/home/section/GoatNation.js';
import HomeGoatToMars from '../components/home/section/GoatToMars.js';
import HomeGoatAI from '../components/home/section/GoatAI.js';
import HomeGOATREX from '../components/home/section/GOATREX.js';
import HomeGoatEstate from '../components/home/section/GoatEstate.js';
import HomeGoatACADEMY from '../components/home/section/GoatACADEMY.js';
import HomeGoatAlphagoat from '../components/home/section/GoatAlphagoat.js';
import HomeGoatArt from '../components/home/section/GoatArt.js';
import HomeGoatTeam from '../components/home/section/GoatTeam.js';
import HomeGoatMap from '../components/home/section/TheGoatMap.js';
import HomeGoatRoadMap from '../components/home/section/GoatRoadMap.js';

function Home() {
    return (
      <div className="home">
        <HeaderGoat className="header-black"></HeaderGoat>
        <HomeFoto />
        {/* <HomeVideo /> */}
        <HomeGoatFromDay0 />
        <HomeGoatNation />
        <HomeGoatMap />
        <HomeGoatRoadMap />
        <HomeGoatToMars />
        <HomeGoatAI />
        <HomeGOATREX />
        <HomeGoatEstate />
        <HomeGoatAlphagoat />
        <HomeGoatACADEMY />
        <HomeGoatArt />
        <HomeGoatTeam />
      </div>
    );
  }
  
  export default Home;