
import React, { useState, Component } from 'react';

import { testi, lingua_sito } from "../../../language/lang.js";

import { Waypoint } from 'react-waypoint';

function PointMap(param) {
    const [attivo, impostaAttivo] = useState(false);
    
    const classe = attivo ? 'box-roadmap-element roadmap-active' : 'box-roadmap-element';
    const classe1 = param.nascosto ? 'box-roadmap-element-hidden' : classe;
    const classe_mobile = param.nascosto ? 'roadmap-grid-element roadmap-grid-element-hiddenmob' : 'roadmap-grid-element';
    
    return (
        <Waypoint bottomOffset='400px' onEnter={() => impostaAttivo(true)} onLeave={() => impostaAttivo(false)} >
            <div style={param.style} className={classe_mobile}>
                <div className={classe1} >
                    <h4>{param.titolo}</h4>
                    {param.children}
                </div>
            </div>
        </Waypoint>
    );
}

export class HomeSection extends Component {
    render() {
        const lingua = lingua_sito();
        return (
            <div style={{
                background: `url(${process.env.PUBLIC_URL + '/assets/img/GoatRoadMap/tigre1.png'})`,
            }} className='roadmap-background'>
                <div className="container container-padding">

                    <div style={{
                        background: `url(${process.env.PUBLIC_URL + '/assets/img/GoatRoadMap/Rectangle282.png'})`,
                    }} className='riga_roadmap' />

                    <div className="roadmap-grid">
                        <PointMap titolo={testi('goatroadmap_goatmint')}>
                            {lingua == "it" ? (
                                <ul>
                                    <li>È tempo di Mintare: nascono i GENESIS GOAT</li>
                                    <li>Chi partecipa al minting si porta a casa l'accesso a vita alla MARS Academy.</li>
                                    <li>Lancio dell'ALPHA GOAT CLUB, riservato a chi possiede almeno 5 GOAT</li>
                                    <li>Prima GOAT NIGHT, accessibile a tutti gli holders</li>
                                    <li>Beneficenza</li>
                                    <li>...e molto di più!</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>It's Mint Time: GENESIS GOATs are born</li>
                                    <li>Whoever participates in the minting takes home lifetime  access to the MARS Academy.</li>
                                    <li>Launch of ALPHA GOAT CLUB, reserved to those who own at least 5 GOATs</li>
                                    <li>First GOAT NIGHT, accessible to all holders</li>
                                    <li>Charity</li>
                                    <li>...and much more!</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true" />
                        
                        <PointMap nascosto="true" />
                        <PointMap titolo={testi('goatroadmap_stakegoat')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>Blocca la tua Genesis GOAT e ottieni l'accesso al nostro primo servizio → GOATECH</li>
                                    <li>TOKEN $LATTE. La tua Genesis GOAT ti permette di generare 4 $LATTE al giorno... a cosa serve? Lo scoprirai nella prossima fase.</li>
                                    <li>Seconda GOAT NIGHT per tutti gli holders</li>
                                    <li>Primo vantaggio ESCLUSIVO per ALPHA GOAT CLUB (TBA)</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Stack your Genesis GOAT and get access to our first service → GOATECH</li>
                                    <li>TOKEN $LATTE. Your Genesis GOAT allows you to generate 4 $LATTE daily... what is it for? You'll find out in the next phase.</li>
                                    <li>Second GOAT NIGHT for all holders</li>
                                    <li>First EXCLUSIVE benefit for ALPHA GOAT CLUB (TBA)</li>
                                </ul>
                            )}
                        </PointMap>

                        
                        <PointMap titolo={testi('goatroadmap_babygoat')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>2 Genesis GOAT + $LATTE = 1 BABY GOAT</li>
                                    <li>8.888 BABY GOAT prenderanno vita: 4.444 GRATIS per i possessori di 2 Genesis Goat, gli altri 4.444 saranno mintabili in vendita pubblica da chiunque</li>
                                    <li>Chi partecipa al minting si porta a casa l'accesso a vita alla MARS Academy...</li>
                                    <li>Metti in stacking il tuo BABY GOAT e ottieni l'accesso a GOATREX, il nostro secondo servizio!</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>2 Genesis GOAT + $LATTE = 1 BABY GOAT</li>
                                    <li>8,888 BABY GOAT will come to life: 4,444 FREE for holders of 2 Genesis Goats, the other 4,444 will be mintable on public sale by everybody</li>
                                    <li>Whoever participates in the minting takes home lifetime access to the MARS Academy...</li>
                                    <li>Stack your BABY GOAT and get access to <b>GOATREX</b>, our second service!</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true" />

                        <PointMap nascosto="true" />
                        <PointMap titolo={testi('goatroadmap_goatmind')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>Lancio del GOAT Mastermind riservato all'ALPHA GOAT CLUB: 1 chiamata a settimana per 1 anno con GOAT PARTNERS.</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Launch of the GOAT Mastermind reserved for the ALPHA GOAT CLUB: 1 call per week for 1 year with GOAT PARTNERS.</li>
                                </ul>
                            )}
                        </PointMap>

                        <PointMap titolo={testi('goatroadmap_goatbrand')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>Vestiti come un vero GOAT, è la fase del lancio del Brand che ci unirà sotto un'unica bandiera.</li>
                                    <li>Lancio della GOAT NATION Academy: accademia ufficiale dove tratteremo di NFT, Mindset, Leadership, Riprogrammazione mentale e molto altro...</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Dress like a true GOAT, it's the phase of the launch of the Brand that will unite us under one flag.</li>
                                    <li>Launch of the GOAT NATION Academy: official academy where we will cover NFT, Mindset, Leadership, Mental Reprogramming, and much more...</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true" />

                        <PointMap nascosto="true" />
                        <PointMap titolo={testi('goatroadmap_goatcommerce')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>Lancio di GOAT-COMMERCE, l'e-commerce ufficiale di GOAT NATION, all'interno troverete: il Marchio, la nostra Accademia e molto altro ancora...</li>
                                    <li>Come puoi fare acquisti su GOAT-COMMERCE? Esattamente, con il Token $LATTE!</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Launch of GOAT-COMMERCE, the official e-commerce of GOAT NATION, inside you will find: the Brand, our Academy and much more...</li>
                                    <li>How can you shop on GOAT-COMMERCE? Exactly, with the Token $LATTE!</li>
                                </ul>
                            )}
                        </PointMap>

                        <PointMap titolo={testi('goatroadmap_goatalien')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>I GOAT attirano molta attenzione, anche galattica. Vengono condotti strani esperimenti per capire i segreti del loro successo.</li>
                                    <li>Creazione della terza collezione: The Alien GOATs</li>
                                    <li>Minting delle Astronavi: GOAT-Spaceships disponibili TBA</li>
                                    <li>Chi partecipa al minting si porta a casa l'accesso a vita alla MARS Academy</li>
                                    <li>Metti in stacking il tuo ALIEN GOAT e ottieni l'accesso a GOATESTATE, il nostro terzo servizio!</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>GOATs attract a lot of attention, including galactic attention. Strange experiments are conducted to understand the secrets of their success.</li>
                                    <li>Creation of the third collection: The Alien GOATs</li>
                                    <li>Minting of Spaceships: GOAT-Spaceships available TBA</li>
                                    <li>Those who participate in minting take home lifetime access to the MARS Academy</li>
                                    <li>Stack your ALIEN GOAT and get access to GOATESTATE, our third service!</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true" />

                        <PointMap nascosto="true" />
                        <PointMap titolo={testi('goatroadmap_goatvention')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>Evento dal vivo a Dubai in esclusiva solo per gli holders.</li>
                                    <li>Tutto il Team, i nostri Mastermind GOATs e altri personaggi ESCLUSIVI saranno presenti.</li>
                                    <li>Emirates Business Class Giveaway per i titolari della collezione.</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Live event in Dubai exclusive only for holders.</li>
                                    <li>The whole Team, our Mastermind GOATs and other EXCLUSIVE characters will be present.</li>
                                    <li>Emirates Business Class Giveaway for the holders of the collection.</li>
                                </ul>
                            )}
                        </PointMap>
                        
                        <PointMap titolo={testi('goatroadmap_goatverse')} >
                            {lingua == "it" ? (
                                <ul>
                                    <li>Fondazione della GOAT NATION ufficiale sul Metaverso: popolata da tutti i GOAT (Genesis, Baby, Alien) che potranno usufruire di vari privilegi.</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>Foundation of the official GOAT NATION on Metaverse: populated by all GOATs (Genesis, Baby, Alien) who will be able to take advantage of various privileges.</li>
                                </ul>
                            )}
                        </PointMap>
                        <PointMap nascosto="true" />
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;