import React, { Component } from 'react';

export class GoatechBtnMobile extends Component {
    
    render() {
        return (<div className='goatech-mobile-btns'>
            <a href="/goatech-mint-fieno">Buy Fieno</a>
            <a href="/goatech-stats">My Goatech Stats</a>
            {/* <a href="/goatech-pamm-history">PAMM<br />History</a> */}
        </div>);
    }
}

export default GoatechBtnMobile;