
import forceLogout from '../../../helpers/forceLogout';

export function getMetamaskProvider() {
    if (typeof window.ethereum === 'undefined') {
        return null;
    }
    if (typeof window.ethereum.providers === 'undefined') {
        if (window.ethereum.isMetaMask) {
            return window.ethereum;
        }
    } else {
        const provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
        if (provider) {
            return provider;
        }
    }
}

export function getCoinbaseProvider() {
    if (typeof window.ethereum === 'undefined') {
        return null;
    }
    if (typeof window.ethereum.providers === 'undefined') {
        if (window.ethereum.isCoinbaseWallet) {
            return window.ethereum;
        }
    } else {
        const provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
        if (provider) {
            return provider;
        }
    }
}

export function getSelectedProvider(isForceLogout = true) {
    const wallet = localStorage.getItem("wallet_connected");
    
    let connector = null;
    if (wallet === "metamask") {
        connector = getMetamaskProvider();
    } else if (wallet == "coinbase") {
        connector = getCoinbaseProvider();
    }
    if (connector == null) {
        if(isForceLogout) {
            forceLogout();
        }
        return false;
    }
    return connector;
}

export default getMetamaskProvider;
