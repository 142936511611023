
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div style={{
                    background: `
                    url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatAlphagoat/Rectangle264.png'}) center center no-repeat
                    `,
                    backgroundSize: 'cover',
                    backgroundColor: 'transparent'
                }} className='section2-background goat-alphagoat-background goat-alphagoat-background-mobile'>
                    <div className="container">
                        <div style={{ textAlign: 'left', paddingTop: 70, maxWidth: 610 }} className="section2-left goat-section-mobile">
                            <BadgeHome testo="ÉLITE GROUP" style={{ color: '#FFB800' }} />
                            
                            <h3 dangerouslySetInnerHTML={{ __html: testi('goatalphagoat_h3') }} className="h3-section2"></h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goatalphagoat_parag') }} style={{ fontSize: 20, lineHeight: '28px', color: 'white', opacity: 0.8 }} className="parag-section2"></p>
                        </div>
                    </div>

                    <div className='goat-alphagoat-mobile'>
                        <img src="assets/img/HomeGoatAlphagoat/shadow_34.png" />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;