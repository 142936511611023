
import { ethers } from "ethers";
import { getAbiContract, getAddressContract } from './getContractInfo';

import { getCurrentChain } from '../wallet_generic/helpers/getCurrentChain';
import { isChainCorrect } from "../wallet_generic/helpers/isChainCorrect";

import { getSelectedProvider } from '../wallet_generic/helpers/getProvider';
import { ChangeChainRedirect } from '../wallet_generic/helpers/changeChainRedirect';

import { Reti } from "../wallet_generic/Wallets";
import { getUSDContract } from "./usdc/getUSDContract";

const handleError = (error) => {
    if (error.includes("code=INSUFFICIENT_FUNDS")) {
        return 'Insufficent funds to complete the transaction';
    } else if (error.includes("tokens-are-0")) {
        return 'You can mint at least 1 bale of hay.';
    } else if (error.includes("invalid-proof")) {
        return 'You must have at least 1 Goat to mint a hay.';
    } else if(error.includes("User denied transaction signature.")) {
        return 'Transaction rejected by the user.';
    } else {
        return 'An error occurred, try again';
    }
};

export class GoatechHayMint {
    
    static async runMint(data_mint, call) {

        if (!data_mint.address || data_mint.address == ""
            || !data_mint.mintQnt || data_mint.mintQnt == "") {
            return false;
        }

        const address = data_mint.address;
        const mintQnt = data_mint.mintQnt;
        const proofs = data_mint.proofs;

        const connector = getSelectedProvider();
        
        // Controllo la rete connessa
        const _chainId = await getCurrentChain(connector);
        if (!isChainCorrect(_chainId, Reti.BINANCE)) {
            ChangeChainRedirect(Reti.BINANCE);
            return false;
        }
        
        // Prendo le info del contratto
        const contractAbi = getAbiContract();
        const contractAddress = getAddressContract();

        const provider = new ethers.providers.Web3Provider(connector);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractAbi, signer);

        // Costo del mint
        let mintPrice = await contract.getMintPrice();
        mintPrice = mintPrice.mul(mintQnt)._hex;
        
        try {

            call.setWaitingTransaction('Please allow access to your USDC tokens (the request to the tokens is already set only for the amount needed for this transaction)...');

            // Approve da USDC
            const USDC = await getUSDContract(provider);
            const request = await USDC.approve(contractAddress, mintPrice);
            call.setWaitingTransaction('Awaiting approval...');
            await request.wait();

            call.setWaitingTransaction('Waiting for your confirmation of the transaction...');
            
            // Avvio il mint
            const mintMode = await contract.mint(mintQnt, proofs);
            
            call.setWaitingTransaction('Done! Transaction pending confirmation on the network....');

            const receipt = await mintMode.wait().then((res) => {
                return true;
            }, error => {
                return 'Error in transaction verification ' + (error.message ? error.message : '');
            }).catch((error) => {
                return 'Error in transaction verification ' + (error.message ? error.message : '');
            });
            
            return receipt;

        } catch (error) {
            if (!error.message) {
                return handleError('');
            }
            return handleError(error.message);
        }
    }
}