import React, { Component } from 'react';
import { ethers } from "ethers";

import moment from 'moment';

import { EndPointMethod, RequestBackend } from '../../../components/area_riservata/backend/RequestBackend.js';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import { showError } from '../../../components/ui/ToastErrore.js';
import { Reti } from '../../../components/wallet_generic/Wallets.js';
import { getSelectedProvider } from '../../../components/wallet_generic/helpers/getProvider.js';

export class GoatechVerifyAccount extends Component {

    constructor(props) {
        super(props);

        let redirect_url = window.location.search.substring(1);
        redirect_url = redirect_url.toLowerCase();
        redirect_url = redirect_url.replace("http", "");
        redirect_url = redirect_url.replace("/", "");
        redirect_url = redirect_url.replace("redirect=", "");

        this.state = {
            'address': '',
            'message': '',
            'nonce': '',
            'ready': false,
            'redirect': redirect_url,
            'notInGoatech': false
        }

        this.setAddress = this.setAddress.bind(this);
    }

    async setAddress(address) {
        this.setState({
            'address': address,
        });
        this.loadNonce(address);
    }

    async loadNonce(address) {
        const request = new RequestBackend(address, EndPointMethod.REQUEST_NONCE_AUTH);
        const response = await request.run();

        if (request.getStatusCode() == 200 && !request.isResponseError()) {
            if (response["message"]) {
                this.setState({
                    'ready': true,
                    'message': response["message"],
                    'nonce': response["nonce"]
                });
            }
        } else {
            if (request.isResponseError()) {
                if (request.getStatusCode() == 501) {
                    this.setState({ notInGoatech: true });
                }
                if (response["error"]) {
                    showError({ 'titolo': 'Goatech Access', 'testo': response["error"] });
                    return;
                }
            }
            showError({ 'titolo': 'An error occurred', 'testo': 'An error occurred in receiving the message to be signed' });
        }
    }

    async signMessage() {
        if (this.state.notInGoatech) {
            showError({ 'titolo': 'Goatech Access', 'testo': 'Your wallet is not yet ready to access Goatech. If you have recently mint a Goat you should wait a few minutes...' });
            return;
        }
        if (!this.state.ready) {
            showError({ 'titolo': 'An error occurred', 'testo': 'Please wait for the message to be signed... try again' });
            return;
        }

        const provider = getSelectedProvider();
        const _this = this;

        const message = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(this.state.message));

        await provider.request({
            method: 'personal_sign',
            params: [
                message,
                this.state.address
            ]
        }).then((signature) => {
            const request = new RequestBackend(_this.state.address, EndPointMethod.LOGIN, {
                signature: signature,
                nonce: this.state.nonce
            });
            request.run().then((data) => {
                if (request.isResponseError()) {
                    // Token scaduto
                    if (request.getStatusCode() == 404) {
                        showError({ 'titolo': 'Token expired', 'testo': 'Your message has expired. Please try again' });
                        _this.loadNonce(_this.state.address);
                        return;
                    }
                    showError({ 'titolo': 'An error occurred', 'testo': 'Unable to verify message: ' + data["error"] });

                } else if (data["status"] && data["status"] == "success") {

                    let scadAuthorizationToken = data["data"]["AuthorizationToken"]["expires"];
                    scadAuthorizationToken = moment(scadAuthorizationToken).toDate().toUTCString();

                    let scadRefreshToken = data["data"]["RefreshToken"]["expires"];
                    scadRefreshToken = moment(scadRefreshToken).toDate().toUTCString();
                    
                    let scadAuth = moment().add(1, 'year').toDate().toUTCString();

                    // Login riuscito con successo, setto i cookie
                    document.cookie = "AuthorizationToken=" + data["data"]["AuthorizationToken"]["valore"] + ";expires=" + scadAuthorizationToken;
                    document.cookie = "RefreshToken=" + data["data"]["RefreshToken"]["valore"] + ";expires=" + scadRefreshToken;
                    document.cookie = "AuthorizationGoatech=true;expires=" + scadAuth;

                    showError({ 'titolo': 'Welcome back, Goater!', 'testo': "It's so good to see you again!" });

                    window.setTimeout(function () {
                        window.location.href = window.location.protocol + '//' + window.location.host + '/' + _this.state.redirect;
                    }, 1200);
                } else {
                    showError({ 'titolo': 'An error occurred', 'testo': '' });
                }
            });
        }, error => {
            showError({ 'titolo': 'An error occurred', 'testo': 'Request cancelled by user' });
        });
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat checkLogged="true" skipRedirectLogin="true" rete={Reti.BINANCE} setAddress={this.setAddress} className="header-black" />

                <div className='container-top'>
                    <div className='container-account container-switch-network'>
                        <div className='text-switch-network'>
                            <h3>Verify your Wallet</h3>

                            <p className='switch-network-description'>
                                Before accessing your Goatech account, <b>we need to verify your wallet.</b><br />
                                To proceed, you must sign a message from your wallet. This is completely free!</p>
                            <p className='switch-network-btn'>
                                <a onClick={() => this.signMessage()} className={'btn-block-orange ' + (!this.state.ready ? 'disabled' : '')} >
                                    Verify your wallet
                                </a>
                            </p>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatechVerifyAccount;