import { getWeb3Provider } from "./helpers/getWeb3Provider";
import { getAddressContract, getGenesisBlock } from './getContractInfo';

import moment from 'moment';
import 'moment-timezone';

export async function getMintedNFT(address, supply) {

    const web3 = await getWeb3Provider();
    const contract = web3.contract;
    const provider = web3.provider;

    let data_start_nft = moment.tz("2022-05-25", "Europe/Rome");

    // Prendo la lista dei token in possesso dal wallet
    var array_nfts = new Array();

    let tokenId;
    let tokenURI;
    for (var i = 0; i < supply; i++) {
        tokenId = await contract.tokenOfOwnerByIndex(address, i);
        tokenId = parseInt(Number(tokenId));

        tokenURI = await contract.tokenURI(tokenId);

        array_nfts.push({ tokenId, tokenURI, data: data_start_nft });
    }

    // Data del mint (I token trasferiti devono comunque prendere la data del mint)
    // Prendo tutte le transazioni fatte dal contratto
    const logs_mint = await provider.getLogs({
        fromBlock: getGenesisBlock(),
        toBlock: 'latest',
        address: getAddressContract(),
    });

    // Cerco le date degli NFT
    let tokenIdNft;
    let tokenBlock;
    let token;

    for (var i = 0; i < logs_mint.length; i++) {
        if (logs_mint[i].topics && logs_mint[i].topics[3]) {

            // topics[3] è il numero del token trasferito
            tokenIdNft = parseInt(Number(logs_mint[i].topics[3]));

            // Controllo se il token fa parte di quelli del wallet
            token = array_nfts.find(function (item) {
                return item.tokenId == tokenIdNft
            });

            if (token && !token.data_minted) {

                // Prendo i dettagli del blocco per ricavare la data
                tokenBlock = await provider.getBlock(logs_mint[i].blockNumber);

                // Salvo la data del blocco sull'array ID_TOKEN => DATA
                token.data_minted = moment.unix(tokenBlock.timestamp).format("YYYY-MM-DD");
            }
        }
    }

    // Data di consegna NFT (Da quando ha l'nft nel wallet)
    // Prendo le transazioni fatte verso l'address
    const filter = contract.filters.Transfer(null, address);
    const logs_transfer = await provider.getLogs({
        fromBlock: getGenesisBlock(),
        toBlock: 'latest',
        address: getAddressContract(),
        topics: filter.topics
    });

    let tokenIdNft2;
    let token2;
    let tokenBlock2;

    for (var i = 0; i < logs_transfer.length; i++) {
        if (logs_transfer[i].topics && logs_transfer[i].topics[3]) {

            // topics[3] è il numero del token trasferito
            tokenIdNft2 = parseInt(Number(logs_transfer[i].topics[3]));

            // Controllo se il token fa parte di quelli del wallet
            token2 = array_nfts.find(function (item) {
                return item.tokenId == tokenIdNft2
            });

            if (token2 && !token2.data_transfer) {

                // Prendo i dettagli del blocco per ricavare la data
                tokenBlock2 = await provider.getBlock(logs_transfer[i].blockNumber);

                // Salvo la data del blocco sull'array ID_TOKEN => DATA
                token2.data_transfer = moment.unix(tokenBlock2.timestamp).format("YYYY-MM-DD");
            }
        }
    }

    return array_nfts;
}