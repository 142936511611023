
export const lingua_sito = () => {
    var selected_lang = "en";
    if (localStorage.getItem("language")) {
        if (localStorage.getItem("language") == "it") {
            selected_lang = "it";
        }
    }
    return selected_lang;
}

export const testi = (key) => {
    const selected_lang = lingua_sito();
    if (lang[selected_lang][key]) {
        return lang[selected_lang][key];
    }
    return lang["en"][key];
}

const lang = {
    en: {

        "header_whitepaper_link": "pdf/WHITEPAPER_GOAT_NATION_EN.pdf?v=2", 

        "welcome_h1": "WELCOME TO<br />THE GOAT NATION",
        "fromday0_h3": "The best utilities,<br />from day 0",
        "fromday0_parag": "Imagine to have access to the best utilities on the market since the first day you minted your NFTs... well, thanks to GOAT NATION this is now a beautiful reality.",
        "goatnation_h3": "WE ARE ALL GOAT NATION",
        "goatnation_parag": "What is the thing that impact the most on the success of a human?<br />THE ENVIRONMENT. Whatever you might have or not have right now depends on the environment that surrounds You. GOAT NATION is creating the best environment for people who wants to BE MORE, ACHIEVE MORE and HAVE MORE",
        "goatmap_h3": "THE GOATMAP",
        "goatmap_parag": "From 0 to GOAT",

        "goatroadmap_goatmint": "GOAT MINT",
        "goatroadmap_stakegoat": "STAKE YOUR GOAT",
        "goatroadmap_babygoat": "BABY GOAT",
        "goatroadmap_goatmind": "GOAT MIND",
        "goatroadmap_goatbrand": "GOAT BRAND",
        "goatroadmap_goatcommerce": "GOAT-COMMERCE",
        "goatroadmap_goatalien": "ALIEN ABDUCTION",
        "goatroadmap_goatvention": "GOATVENTION",
        "goatroadmap_goatverse": "GOATAVERSE",

        "goat_to_mars_h3": "GOAT TO MARS",
        "goat_to_mars_parag": "True GOATs know their way around, understand the value of projects instantly, and know that minting is where they take home the biggest advantage.<br />Purchasing a GOAT in minting will entitle you to lifetime access to the MARS Academy.<br /><br />You can look at it as a €1,500 pass but it's much more than that, it's your ticket to the most comprehensive knowledge base in the crypto, NFT and exchange markets.<br /><br />How fast are you?",

        "goatech_h3": "GOATECH",
        "goatech_parag": "To be a GOAT in the Crypto market, knowledge is not enough.<br />You need to combine it with technology and experience.<br />We assembled the best knowledge and designed the best technology to go further any expectations.<br />Are we talking about a common Crypto BOT? Of course NOT!<br /><br />We have real GOATs of the crypto Industry behind GOATECH.<br />What will you need to do?<br />Stake your GENESIS GOATs and let the Crypto Flow",

        "goatrex_h3": "GOATREX",
        "goatrex_parag": "Forex: another world full of sheep filling their mouths and GOATs making a difference... and real money.<br />Have a BABY GOAT will give you access to managed GOAT Fund investment strategies; What does that mean?<br /><br />Stake your BABY GOAT and enjoy monthly percentage returns on capital.",
        
        "goatestate_h3": "GOAT ESTATE",
        "goatestate_parag": "Imagine to feel like home in every part of the world. Imagine being resident of the best places you’ve ever dreamed of. We are taking REAL ESTATE to a complete next level.<br /><br />Be sure to have both GENESIS and BABY, and you will have access to the third collection.<br /><br />The ALIEN GOAT. Your Goats will be abducted and strange experiments will make remarkables alterations to it. Be ready for the Revolution of this industry, in the real world and in the Metaverse",

        "goatalphagoat_h3": "Alphagoat Club",
        "goatalphagoat_parag": "That’s La crème de la crème. To earn the status of AlphaGOAT you will have to have a minimun of 5 GOATs in your wallet.<br />It won't be a matter of collecting, mind you, but of gaining access to the Discord of the Élite and then being able to preview theservices offered in Roadmap and participate in AlphaTalks, moments of meeting and exchange of ideas for a few, very few.<br /><br />Only the members of the AlphaGoat Club will have access to the GOAT-MIND: an exclusive Mastermind with 4 Real Goats in the entrepreneurial world that will attend 1 video-call per week for ONE YEAR. That’s what we call, a shortcut for success.<br /><br />The more information you have, the more opportunities you have to break through. Even under the AlphaGoat Club, access to groups and information will be regulated by the number of GOATs owned.<br />",

        "goatacademy_h3": "GOAT ACADEMY",
        "goatacademy_parag": "The GOAT NATION Academy is an entire journey where we will talk about NFTs, Mindset, Leadership, Subconscious Mind Reprogramming, and much more. <br /><br />The contents of the academy will be dynamic will evolve during time and will be updated in order to provide the best tools and informations for you to become a real GOAT in every aspect of your life.",
        
        "goatteam_h3": "THE GOATeam",
        "goatteam_parag": "When the going gets tough it's the team that makes the difference.",

        "goatart_h3": "GOAT ART",
        "goatart_parag": "The art of our collection has been studied down to<br />the smallest detail and is of a quality never seen<br />before.",

        "faq_h3": "FAQ",
        "faq_parag": "We believe artists need to be compensated for every<br />sale, not just the first one!",
        "faq_quest1": "What’s GOAT Nation?",
        "faq_resp1": "GOAT Nation is a collection of 4.444 unique 3D GOAT randomly generated, hunting in the ETH Blockchain as ERC-721 NFTs and hosted in IPFS.",
        "faq_quest2": "WOW! How do i get one?",
        "faq_resp2": "If you are in WL you will have access to minting directly from our official website.<br />If you have not managed to enter WL, you will be able to buy your GOAT in the secondary market on Opensea.",
        "faq_quest3": "When is the Launch?",
        "faq_resp3": "TBA",
        "faq_quest4": "Who is part of the team?",
        "faq_resp4": "You can find the section dedicated to the Team on our official website to find out who we are.",
        "faq_quest5": "Is there a Whitelist?",
        "faq_resp5": "Yes, we have whitelists and you can stay updated on discord.",
        "faq_quest6": "Next steps?",
        "faq_resp6": "We want to respect the roadmaps steps. And that’s only the beginning.",
    },
    it: {

        "header_whitepaper_link": "pdf/WHITEPAPER_GOAT_NATION_IT.pdf", 

        "welcome_h1": "BENVENUTO<br />SU GOAT NATION",
        "fromday0_h3": "Le migliori utilities,<br />dal giorno 0",
        "fromday0_parag": "Immagina di avere accesso alle migliori utility sul mercato dal primo giorno in cui hai mintato i tuoi NFT... beh, grazie a GOAT NATION questa è ora una bella realtà.",
        "goatnation_h3": "SIAMO TUTTI PARTE DI GOAT NATION",
        "goatnation_parag": "Qual è la cosa che incide di più sul successo di un uomo?<br />L'AMBIENTE. Qualsiasi cosa tu possa avere o non avere in questo momento dipende dall'ambiente che ti circonda. GOAT NATION sta creando l'ambiente migliore per le persone che vogliono essere di più, ottenere di più e avere di più.",
        "goatmap_h3": "THE GOATMAP",
        "goatmap_parag": "Dal giorno 0 a GOAT",

        "goatroadmap_goatmint": "GOAT MINT",
        "goatroadmap_stakegoat": "STAKE YOUR GOAT",
        "goatroadmap_babygoat": "BABY GOAT",
        "goatroadmap_goatmind": "MENTALITA’ GOAT",
        "goatroadmap_goatbrand": "BRAND GOAT",
        "goatroadmap_goatcommerce": "GOAT-COMMERCE",
        "goatroadmap_goatalien": "RAPIMENTO ALIENO",
        "goatroadmap_goatvention": "GOATVENTION",
        "goatroadmap_goatverse": "GOATAVERSE",

        "goat_to_mars_h3": "GOAT SU MARTE",
        "goat_to_mars_parag": "I veri GOAT sanno come muoversi, capiscono il valore dei progetti all'istante e sanno che il minting è dove loro portano a casa il maggior vantaggio.<br />L'acquisto di un GOAT nel minting ti darà diritto all'accesso a vita alla MARS Academy.<br /><br />Puoi considerarlo come un pass da 1.500 euro, ma è molto di più, è il tuo biglietto per la più completa base di conoscenze nel mercato delle criptovalute, NFT e degli scambi.<br /><br />Quanto sei veloce?",

        "goatech_h3": "GOATECH",
        "goatech_parag": "Per essere un GOAT nel mercato Crypto, la conoscenza non è sufficiente.<br />Devi combinarla con la tecnologia e l'esperienza.<br />Abbiamo assemblato le migliori conoscenze e progettato la migliore tecnologia per andare oltre ogni aspettativa.<br />Stiamo parlando di un comune Crypto BOT? Naturalmente no!<br /><br />Abbiamo dei veri GOAT dell'industria crypto dietro GOATECH.<br />Cosa dovrai fare?<br />",

        "goatrex_h3": "GOATREX",
        "goatrex_parag": "Forex: un altro mondo pieno di pecore che si riempiono la bocca e di GOAT che fanno la differenza... e soldi veri.<br />Avere un BABY GOAT ti darà accesso alle strategie di investimento del Fondo GOAT; cosa significa?<br /><br />Metti in stacking il tuo BABY GOAT e goditi i ritorni percentuali mensili sul capitale.",

        "goatestate_h3": "GOAT ESTATE",
        "goatestate_parag": "Immaginate di sentirvi a casa in ogni parte del mondo. Immagina di essere residente nei posti migliori che hai sempre sognato. Stiamo portando REAL ESTATE ad un livello completamente superiore.<br /><br />Assicurati di avere sia GENESIS che BABY, e avrai accesso alla terza collezione.<br /><br />La capra aliena. Le vostre capre saranno rapite e strani esperimenti ne faranno delle alterazioni notevoli. Siate pronti per la rivoluzione di questa industria, nel mondo reale e nel Metaverso",

        "goatalphagoat_h3": "Alphagoat Club",
        "goatalphagoat_parag": "La crème de la crème. Per guadagnare lo status di AlphaGOAT dovrai avere un minimo di 5 GOAT nel tuo portafoglio.<br />Non si tratterà di collezionare, attenzione, ma di ottenere l'accesso al Discord dell'Élite e quindi poter provare in anteprima i servizi offerti in Roadmap e partecipare agli AlphaTalks, momenti di incontro e scambio di idee per pochi, pochissimi.<br /><br />Solo i membri dell'AlphaGoat Club avranno accesso al GOAT-MIND: un esclusivo Mastermind con 4 Veri GOAT del mondo imprenditoriale che parteciperanno a 1 video-call a settimana per UN ANNO. Questo è quello che noi chiamiamo, una scorciatoia per il successo.<br /><br />Più informazioni hai, più opportunità hai di sfondare. Anche sotto il Club AlphaGoat, l'accesso ai gruppi e alle informazioni sarà regolato dal numero di GOAT posseduti.",

        "goatacademy_h3": "ACADEMY GOAT",
        "goatacademy_parag": "La GOAT NATION Academy è un intero percorso dove parleremo di NFT, Mindset, Leadership, Riprogrammazione della Mente Subconscia e molto altro ancora.<br /><br />I contenuti dell'accademia saranno dinamici, si evolveranno nel tempo e saranno aggiornati per fornirti i migliori strumenti e informazioni per diventare un vero GOAT in ogni aspetto della tua vita.",
        
        "goatteam_h3": "Il GOATeam",
        "goatteam_parag": "Quando il gioco si fa duro è la squadra che fa la differenza.",
        
        "goatart_h3": "GOAT ART",
        "goatart_parag": "L'art della nostra collezione è stata studiata fino a<br />il più piccolo dettaglio ed è di una qualità mai vista<br />prima.",

        "faq_h3": "FAQ",
        "faq_parag": "Crediamo che gli artisti debbano essere compensati per ogni vendita,<br />non solo per la prima!",
        "faq_quest1": "Cos'è GOAT Nation?",
        "faq_resp1": "GOAT Nation è una raccolta di 4.444 GOAT 3D univoci generati casualmente nella Blockchain ETH come NFT ERC-721 e ospitati in IPFS.",
        "faq_quest2": "Come ne ottengo uno?",
        "faq_resp2": "Se sei in WL avrai accesso al minting direttamente dal nostro sito ufficiale.<br />Se non sei riuscito ad entrare in WL, potrai acquistare il tuo GOAT su Opensea.         ",
        "faq_quest3": "Quando è il lancio?",
        "faq_resp3": "TBA",
        "faq_quest4": "Chi fa parte del team?",
        "faq_resp4": "Potete trovare la sezione dedicata al Team qui sopra",
        "faq_quest5": "Esiste una whitelist?",
        "faq_resp5": "Sì, abbiamo un sistema whitelist e puoi rimanere aggiornato su discord.",
        "faq_quest6": "Prossimi step?",
        "faq_resp6": "Vogliamo rispettare la roadmap. E questo è solo l'inizio.",
    }
};

export default testi;
