import developer from './abi/dev/contract.json';
import production from './abi/production/contract.json';
import { isDev } from '../wallet_generic/helpers/isDev';

export function getAbiContract() {
    if (isDev()) {
        return developer;
    }
    return production;
}

export function getAddressContract() {
    if (isDev()) {
        return "0x12E402CE9bF670f0AcdA26fdcb0089bA8416380C";
    }
    return "0xb98eDa42741FCE2Ef8Ecc0E35766e59A73cC3eFb";
}

export function getGenesisBlock() {
    if (isDev()) {
        // https://rinkeby.etherscan.io/tx/0xbcb14d83cb6bc5a68c45fc9bebdf4d54fb12113cf8362109428176d03bd68cde
        return 10566349;
    }
    // https://etherscan.io/tx/0x1d2b4f646a14c4390fc69c77bbf1120c576b6677d8298be167351099d0a9d8c0
    return 14655797;
}

export default getAbiContract;
