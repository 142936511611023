import React, { Component } from 'react';

export class GoatechBtnBurn extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<span className={'burn-btn ' + (this.props.disabled ? 'disabled' : '')}>Request Burn</span>);
    }
}

export default GoatechBtnBurn;