
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export class ModalAdminInsertTrans extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mostra_modal: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.impostaModal != this.props.impostaModal) {
            this.setState({ mostra_modal: this.props.impostaModal });
        }
    }

    render() {
        return (
            <div className={'modal-overlay modal-overlay-inframe' + (this.state.mostra_modal ? ' modal-show' : '')} >
                <div className='modal'>
                    <a onClick={() => this.props.toggleModal()} className='modal-close'><FontAwesomeIcon icon={faClose} /></a>

                    <form className='form-trans-update'>
                        <h4>Inserisci transazione</h4>
                        <p>Total PAMM Balance: $ 1.560.000</p>
                        <input type={'number'} placeholder="Valore" />
                        <input type={'submit'} value="Submit" />
                    </form>
                </div>
            </div>
        );
    }
}

export default ModalAdminInsertTrans;