
import React, { Component } from 'react';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div style={{
                background: `url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/Rectangle293.png'})`,
            }} className='goatmap-background'>
                <div id="roadmap" className="container container-padding">
                    <h3 dangerouslySetInnerHTML={{ __html: testi('goatmap_h3') }} style={{ textAlign: 'center' }} className="h3-section2"></h3>
                    <p dangerouslySetInnerHTML={{ __html: testi('goatmap_parag') }} style={{ fontSize: 20, lineHeight: '28px', textAlign: 'center' }} className="parag-section2"></p>
                </div>

                <div className='intest-goatmap'>
                    <div className='container-fluid intest-goatmap_desktop'>
                        <div className='roadmap' style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/mappa.png'}) calc(50% + 25px) -20px no-repeat
                            `
                        }} />
                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/GOAT6.png'}) calc(50% + 360px) bottom no-repeat, 
                            url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/GOAT7.png'}) calc(50% - 360px) bottom no-repeat
                            `
                        }} />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/GOAT2.png'}) calc(50% + 280px) bottom no-repeat, 
                            url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/GOAT3.png'}) calc(50% - 280px) bottom no-repeat
                            `
                        }} />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/GOAT5.png'}) calc(50% + 150px) bottom no-repeat, 
                            url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/GOAT4.png'}) calc(50% - 150px) bottom no-repeat
                            `
                        }} />
                        <div style={{
                            background: `url(${process.env.PUBLIC_URL + '/assets/img/TheGoatMap/GOAT1.png'}) center 105% no-repeat`,
                        }} />
                    </div>
                    <div className='intest-goatmap_mobile'>
                        <img src="assets/img/TheGoatMap/roadmap_mobile2.png" />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;