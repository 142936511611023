
import forceLogout from "../../../helpers/forceLogout";
import { getBackendURI } from "./getBackendURI";

import moment from 'moment';
import Cookies from 'js-cookie';

export const EndPointMethod = {
    REQUEST_NONCE_AUTH: '/user/generate-nonce-token',
    LOGIN: '/user/login',
    REFRESH_TOKEN: '/user/refresh-token',
    GOATECH_MINTPAGE: '/goatech/mint-page',
    GOATECH_STATS: '/goatech/stats'
}

export class RequestBackend {

    constructor(address, method, body = {}) {
        this.state = {
            'address': address,
            'method': method,
            'body': body,
            'isResponseError': false,
            'ResponseCode': 0
        }
        if(Cookies.get('RefreshToken')) {
            this.state.body.AuthorizationToken = Cookies.get('AuthorizationToken');
            this.state.body.RefreshToken = Cookies.get('RefreshToken');
        }
    }

    getParameters() {
        const param = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            credentials: 'include',
            body: new URLSearchParams({
                AuthorizationWallet: this.state.address,
                ...this.state.body
            })
        };
        return param;
    }

    async run() {
        const uri = getBackendURI() + '' + this.state.method;
        const param = this.getParameters();

        // Verifico l'esito della risposta
        const response = await fetch(uri, param).catch((error) => {
            this.state.isResponseError = true;
            return error;
        });
        if (this.state.isResponseError) {
            return response;
        }
        this.state.ResponseCode = response.status;
        
        // 401 = Utente non loggato correttamente
        // 440 = Token dell'utente da aggiornare
        if (response.status == 401) { // Login non valido
            // forceLogout();
            return;
        }
        if (response.status == 440) { // Token scaduto
            return await this.refreshTokenAndResend();
        }

        // Parso il json
        const data = await response.json().catch((error) => {
            this.state.isResponseError = true;
            return error;
        });
        if (data["error"]) {
            this.state.isResponseError = true;
        }
        return data;
    }

    getStatusCode() {
        return this.state.ResponseCode;
    }
    isResponseError() {
        return this.state.isResponseError;
    }

    // Il token temporano scade. Devo rinnovarlo usando il RefreshToken che dura 3 giorni
    async refreshTokenAndResend() {
        // Chiedo un nuovo token
        const refresh = await this.refreshToken();
        if (!refresh) {
            forceLogout();
            return;
        }
        // Rifaccio la richiesta di API
        return await this.run();
    }

    async refreshToken() {
        const request = new RequestBackend(this.state.address, EndPointMethod.REFRESH_TOKEN);
        const response = await request.run();
        if (request.isResponseError() || request.getStatusCode() != 200) {
            return false;
        }
        if(response["status"] && response["status"] == "success") {

            const data = response;
            let scadAuthorizationToken = data["data"]["AuthorizationToken"]["expires"];
            scadAuthorizationToken = moment(scadAuthorizationToken).toDate().toUTCString();

            let scadRefreshToken = data["data"]["RefreshToken"]["expires"];
            scadRefreshToken = moment(scadRefreshToken).toDate().toUTCString();
            
            let scadAuth = moment().add(1, 'year').toDate().toUTCString();

            // Login riuscito con successo, setto i cookie
            document.cookie = "AuthorizationToken=" + data["data"]["AuthorizationToken"]["valore"] + ";expires=" + scadAuthorizationToken;
            document.cookie = "RefreshToken=" + data["data"]["RefreshToken"]["valore"] + ";expires=" + scadRefreshToken;
            document.cookie = "AuthorizationGoatech=true;expires=" + scadAuth;

            return true;
        }
        return false;
    }
}