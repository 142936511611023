
import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export class ToastErr extends Component {

    constructor(props) {
        super(props);

        this.state = {
            titolo: '',
            testo: '',
            showPopup: false
        };
    }

    componentWillMount() {
        if (this.props.setInfoErr) {
            this.setDataError(this.props.setInfoErr);
        }
        this.chiudiModal = this.chiudiModal.bind(this);
    }

    setDataError(data) {
        this.setState({
            ...this.state,
            ...data,
            showPopup: true
        });
    }

    chiudiModal() {
        this.setState({
            ...this.state,
            showPopup: false
        });
    }

    render() {
        return (
            <div className={'modalError ' + (this.state.showPopup ? 'modalErrorShow' : '')}>
                <a onClick={() => this.chiudiModal()} className='modal-close'><FontAwesomeIcon icon={faClose} /></a>
                <h4>{this.state.titolo}</h4>
                <p>{this.state.testo}</p>
            </div>
        );
    }
}

export default ToastErr;

export function showError(err) {
    ReactDOM.unmountComponentAtNode(document.getElementById('modalError'));
    ReactDOM.render(<ToastErr setInfoErr={err} />, document.getElementById('modalError'));
}