
import React, { Component } from 'react';

import DiscordBtn from '../ui/DiscordBtn.js';
import { testi } from "../../../language/lang.js";

function ArtImg(param) {
    return (
        <div className={`art-grid-element ${param.className ? param.className : ''}`} >
            <img src={`assets/img/HomeGoatArt/${param.img}`} />
        </div>
    );
}

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div id="art" style={{ backgroundColor: 'black', height: 'auto' }} className='section2-background'>
                    <div style={{paddingBottom: 0}} className="container-padding">
                        <h3 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: testi('goatart_h3') }} className="h3-section2"></h3>
                        <p style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: testi('goatart_parag') }} className="parag-section-opacity"></p>

                        <div className='art-grid'>
                            <ArtImg img="Nuovi/chupacabra.png" />
                            <ArtImg img="Nuovi/Rectangle94.png" />
                            <ArtImg img="Nuovi/Rectangle95.png" />
                            <ArtImg img="Nuovi/Rectangle98.png" />
                            <ArtImg img="Nuovi/Rectangle102.png?v=2" />
                            <ArtImg img="Nuovi/Rectangle103.png" />
                            <ArtImg img="Nuovi/gamer.png" />
                            <ArtImg img="Nuovi/Rectangle97.png?v=2" />
                            <ArtImg img="Nuovi/Rectangle96.png" />
                            <ArtImg img="Nuovi/Rectangle99.png" />
                            <ArtImg img="Nuovi/Rectangle100.png" />
                            <ArtImg img="Nuovi/lucertola.png" />
                        </div>

                        <div style={{ clear: 'both' }}></div>
                        <DiscordBtn />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;