import React, { Component } from 'react';
import { ethers } from "ethers";

import HeaderGoat from '../components/ui/HeaderGoat.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faPlus, faMinus, faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { faEthereum } from '@fortawesome/free-brands-svg-icons';

import { ContractData, FasiMint } from '../components/ethereum/getContractData';
import { GoatMintExecute } from '../components/ethereum/GoatMintExecute';

import { showError } from '../components/ui/ToastErrore';
import { getInPrivateWLData, getInWLData } from '../components/ethereum/getContractWhitelist.js';

const FasiMintDisplay = {
    PRIVATE: 'Private',
    WHITELIST: 'Whitelisted round',
    PUBLIC: 'Open to the public'
};

export class MintGoat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'faseMint': '',
            'soldOut': false,
            'totalSupply': 0, // Quantità già mintate
            'maxSupply': 0, // Disponibilità da mintare totali
            'mintPrice': 0,

            'maxQntPerWallet': 0, // Quantità massima per il wallet specifico
            'maxQnt': 0, // Quantità massima generale
            'mintedNft': 0, // Già mintati

            'notInWhitelist': false, // Se non è nella whitelist attuale
            'infoLoaded': false,

            'mintQnt': 0,
            'mintAddress': '',
            'showMintAddress': false,
            'transaction_complete': false,
            'transaction_waiting': false,
            'transaction_waiting_text': '',
            'transaction_error': false,
            'transaction_error_msg': '',
        };

        this.setAddress = this.setAddress.bind(this);
        this.toggleShowAddress = this.toggleShowAddress.bind(this);
        this.setWaitingTransactionText = this.setWaitingTransactionText.bind(this);
    }

    formatPrice(price, qnt) {
        let format = ethers.BigNumber.from(price).mul(qnt);
        format = ethers.utils.formatEther(format);
        return (+format).toFixed(4);
    }

    async setAddress(address) {
        
        this.setState({ 'mintAddress': address });

        // Carico le informazioni del contratto
        const result = await ContractData.loadInfo(address);
        this.setState({
            'faseMint': result.faseMint,
            'infoLoaded': true
        });

        // Se è nella whitelist privata (sempre aperta e free)
        const isInPrivateWhitelist = await getInPrivateWLData(address) ? true : false;
        if (result.faseMint == FasiMint.PRIVATE) {
            if (!isInPrivateWhitelist) {
                this.setState({
                    'notInWhitelist': true
                });
                return;
            }
        } else if (result.faseMint == FasiMint.WHITELIST) {
            const isInWhitelist = await getInWLData(address) ? true : false;
            if (!isInWhitelist && !isInPrivateWhitelist) {
                this.setState({
                    'notInWhitelist': true
                });
                return;
            }
        }

        // Carico tutte le info dal contratto
        this.setState({ ...result });

        if (result.mintPrice && result.maxQntPerWallet > 0) {
            this.setState({
                ...this.state,
                'mintQnt': 1
            });
        }
    }

    qntChange(tipo) {

        var mintQnt = this.state.mintQnt;
        if (tipo === "add") {
            mintQnt++;
            if (mintQnt > this.state.maxQntPerWallet) {
                mintQnt = this.state.maxQntPerWallet;
            }
        } else if (tipo === "rem") {
            mintQnt--;
            if (mintQnt < 0) {
                mintQnt = 0;
            }
        }

        this.setState({
            ...this.state,
            'mintQnt': mintQnt,
        });
    }

    mint() {
        if (this.state.mintQnt == 0) {
            showError({ 'titolo': 'Incorrect value', 'testo': 'You can mint minimum 1 goat' });
            return;
        }
        const data_mint = {
            'address': this.state.mintAddress,
            'mintQnt': this.state.mintQnt,
        };

        this.setWaitingTransaction();

        GoatMintExecute.runMint(data_mint, this).then(result => {
            this.stopWaitingTransaction();
            if (result === true) {
                this.setCompletedTransation();
            } else {
                this.setErrorTransaction(result);
            }
        });
    }

    setWaitingTransaction() {
        this.setState({
            ...this.state,
            'transaction_waiting': true,
            'transaction_waiting_text': ''
        });
    }

    setWaitingTransactionText(text) {
        this.setState({
            ...this.state,
            'transaction_waiting_text': text,
        });
    }

    stopWaitingTransaction() {
        this.setState({
            ...this.state,
            'transaction_waiting': false,
            'transaction_waiting_text': ''
        });
    }

    setErrorTransaction(error_msg) {
        this.setState({
            ...this.state,
            'transaction_waiting': false,
            'transaction_error': true,
            'transaction_error_msg': error_msg
        });
    }

    setCompletedTransation() {
        this.setState({
            ...this.state,
            'transaction_waiting': false,
            'transaction_complete': true
        });
    }

    toggleShowAddress() {
        this.setState({
            ...this.state,
            'showMintAddress': !this.state.showMintAddress
        });
    }

    render() {
        return (
            <div className="mint">
                <HeaderGoat setAddress={this.setAddress} checkLogged="true" />

                <div className='container-fluid container-mint'>
                    <div className='mint-page-columns'>
                        <div className='mint-page-column'>
                            <div className='mint-goat-background' style={{
                                background: `
                                url(${process.env.PUBLIC_URL + '/assets/img/fotohome/832-8326155-fog-1@1x.png'}) left bottom no-repeat
                                `
                            }} >
                                <div style={{
                                    background: `
                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(100% - 0px) 170px no-repeat,
                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(100% - 70px) 170px no-repeat,
                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(100% - 140px) 170px no-repeat,

                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) 0 170px no-repeat,
                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) 60px 170px no-repeat,
                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) 120px 170px no-repeat
                                    `
                                }} className='mint-goat-bandiere' />

                                <div style={{
                                    background: `
                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/shadow-3-2@1x.png'}) -200px bottom no-repeat, 
                                    url(${process.env.PUBLIC_URL + '/assets/img/fotohome/shadow-3-3@1x.png'}) right bottom no-repeat
                                    `
                                }} className='mint-goat-shadow' />
                            </div>

                            <img className='mint-goat' src="assets/img/mint/marmo.png" />
                        </div>
                        <div className='mint-page-column centered'>

                            <h1 style={this.state.transaction_complete || this.state.transaction_error ? { display: 'none' } : {}}>Join the<br />goat nation</h1>
                            <p className='titleTxComplete' style={this.state.transaction_complete ? { display: 'block' } : { display: 'none' }} >Success!</p>
                            <p className='titleTxError' style={this.state.transaction_error ? { display: 'block' } : { display: 'none' }} >Transaction failed</p>

                            <div className={this.state.notInWhitelist ? 'box-mint-show mint-not-inwhitelist' : 'box-mint-hide'}>
                                Currently the Mint is open<br />only for whitelisted wallet. Stay tuned!
                            </div>
                            <div className={this.state.infoLoaded && this.state.soldOut ? 'box-mint-show mint-soldout' : 'box-mint-hide'}>
                                Sold Out
                            </div>
                            <div className={this.state.transaction_complete ? 'box-mint-show mint-completed' : 'box-mint-hide'}>
                                See your Goat on:<br />
                                <a className='btnOpensea' href="https://opensea.io/account" target="_blank">opensea.io</a>
                            </div>
                            <div className={this.state.transaction_error ? 'box-mint-show mint-failed-tx' : 'box-mint-hide'}>
                                {this.state.transaction_error_msg}<br /><br />
                                <a style={{ color: 'white', cursor: 'pointer' }} onClick={() => window.location.reload()}>try again</a>
                            </div>

                            <p style={{ marginTop: 45, marginBottom: 15 }} className={this.state.infoLoaded && !this.state.transaction_complete && !this.state.transaction_waiting && !this.state.transaction_error ? 'pAvailable box-mint-show' : 'box-mint-hide'} >
                                Current state: <span className='currentPhaseMint'>{FasiMintDisplay[this.state.faseMint]}</span>
                            </p>

                            <div className={this.state.infoLoaded && !this.state.notInWhitelist && !this.state.soldOut && !this.state.transaction_complete && !this.state.transaction_waiting && !this.state.transaction_error ? 'box-mint-show' : 'box-mint-hide'}>
                                <div style={{ marginTop: 0 }} className='box-mint-now'>
                                    <p className='box-mint-title'>Select the amount of GOAT you want to mint</p>

                                    <div className='boxinput-mint-qnt'>
                                        <div className='mint-input-clmn1'>
                                            <span onClick={() => this.qntChange('rem')} className='selector-mint-qnt'>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </span>
                                            <span id="total-qnt" className='total-qnt'>{this.state.mintQnt}</span>
                                            <span onClick={() => this.qntChange('add')} className='selector-mint-qnt'>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </span>
                                        </div>
                                        <div className='mint-input-clmn2'>
                                            {this.state.maxQnt == this.state.maxQntPerWallet ? this.state.maxQnt : this.state.mintedNft + '/' + this.state.maxQnt} Max <FontAwesomeIcon icon={faLock} />
                                        </div>
                                    </div>

                                    <div className='boxinput-mint-qnt boxtotal-mint'>
                                        <div className='mint-input-clmn1'>
                                            Total
                                        </div>
                                        <div className='mint-input-clmn2'>
                                            {this.formatPrice(this.state.mintPrice, this.state.mintQnt)} ETH <FontAwesomeIcon icon={faEthereum} />
                                        </div>
                                    </div>

                                    <button onClick={() => this.mint()} className='mint-btn'>Mint now and join the nation</button>

                                    <p style={{ margin: 0, marginTop: 10, textAlign: 'center', fontSize: 13 }}>
                                        Your address: {this.state.showMintAddress ? this.state.mintAddress : '0x0************'}
                                        &nbsp;
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.toggleShowAddress()}>
                                            {!this.state.showMintAddress ? <FontAwesomeIcon icon={faEye} /> : ''}
                                            {this.state.showMintAddress ? <FontAwesomeIcon icon={faEyeSlash} /> : ''}
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <p className={this.state.infoLoaded && !this.state.notInWhitelist && !this.state.transaction_complete && !this.state.transaction_waiting && !this.state.transaction_error ? 'pAvailable box-mint-show' : 'box-mint-hide'} >
                                Available Supply: {(this.state.maxSupply - this.state.totalSupply).toLocaleString()} / {(this.state.maxSupply).toLocaleString()}
                            </p>

                            <div className={!this.state.infoLoaded ? 'box-mint-show mint-loading-data' : 'box-mint-hide'}>
                                <FontAwesomeIcon icon={faSpinner} spin />
                            </div>

                            <div className={this.state.transaction_waiting ? 'box-mint-show mint-loading-data' : 'box-mint-hide'}>
                                <p className='pAvailable' style={{ marginBottom: 40 }}>
                                    {this.state.transaction_waiting_text != '' ? this.state.transaction_waiting_text : 'Please check your wallet to confirm the transaction...'}
                                </p>
                                <FontAwesomeIcon icon={faSpinner} spin />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MintGoat;