
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div style={{
                    background: `
                    url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatACADEMY/Rectangle319.png'}) center center no-repeat
                    `, backgroundSize: 'cover'
                }} className='section2-background goat-academy-background'>
                    <div className="container">
                        <div style={{ textAlign: 'left', paddingTop: 100, maxWidth: 420 }} className="section2-right goat-section-mobile">
                            <BadgeHome testo="ACADEMY" style={{ color: '#D9BE94' }} />
                            
                            <h3 dangerouslySetInnerHTML={{ __html: testi('goatacademy_h3') }} className="h3-section2"></h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goatacademy_parag') }} style={{ fontSize: 20, lineHeight: '28px' }} className="parag-section2"></p>
                        </div>
                    </div>
                    
                    <div className='goat-academy-mobile'>
                        <img src="assets/img/HomeGoatACADEMY/19_COWBOY_GOAT_COLOR_CORRECTION_PNG.png" />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;