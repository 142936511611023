import React, { Component, useState } from "react";

import Slider from "react-slick";
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <FontAwesomeIcon className='slick-next' icon={faAngleRight} onClick={onClick} />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <FontAwesomeIcon className='slick-prev' icon={faAngleLeft} onClick={onClick} />
    );
}

function ThumbNtf(props) {
    const [loaded, setLoaded] = useState(false);
    const [showTooltip, setTooltip] = useState(false);

    let data_mint_nft = "";
    let data_transfer_nft = "";
    if(!props.loader) {
        data_mint_nft = moment(props.nft.data_minted).format("DD.MM.YYYY");
        data_transfer_nft = moment(props.nft.data_transfer).format("DD.MM.YYYY");
    }

    return (
        <div className="thumb-nft">
            <div className="thumb-nft-img">
                <img style={loaded ? { display: 'none' } : null} src="assets/img/account/goat.jpeg" />
                {!props.loader ? (
                    <img
                        style={!loaded ? { display: 'none' } : null} onLoad={() => setLoaded(true)}
                        src={`https://nftimg.goat-nation.com/image/${props.nft.tokenId}.png`} />
                ) : null}
                <div style={loaded ? { display: 'none' } : null} className="gradient-loader-nft gradient-loader"></div>
            </div>
            {!props.loader ? (
                <p onMouseEnter={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)} >
                    <span className="num-token">#{props.nft.tokenId}</span>
                    <span className="roi-token">4 $LATTE/DAY</span>
                    <div style={{ clear: 'both' }}></div>
                    <span style={!showTooltip ? { display: 'none' } : null} className="tooltip-date">Minted on {data_mint_nft}<br />Received on {data_transfer_nft}</span>
                </p>
            ) : (
                <p>
                    <span className="num-token">&nbsp;</span>
                    <span className="roi-token">&nbsp;</span>
                    <div style={{ clear: 'both' }}></div>
                </p>
            )}
        </div>
    );
}

export default class MultipleItems extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const settings_loader = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div>
                {this.props.loaded ? (
                    <div>
                        {this.props.listNFT.length > 0 ? (
                            <div className="sliderWalletNft">
                                <Slider {...settings}>
                                    {this.props.listNFT.map((nft, i) => {
                                        return (<ThumbNtf key={i} nft={nft} />)
                                    })}
                                </Slider>
                            </div>
                        ) : (
                            <p>No NFTs in the Wallet :(</p>
                        )}
                    </div>
                ) : (
                    <div className="sliderWalletNft">
                        <Slider {...settings_loader}>
                            {Array.apply(0, Array(5)).map(function (x, i) {
                                return (<ThumbNtf loader key={i} />)
                            })}
                        </Slider>
                    </div>
                )}
            </div>
        );
    }
}