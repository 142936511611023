
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div style={{
                    background: `url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatEstate/Rectangle318.png'})
                    `,
                    backgroundSize: 'cover'
                }} className='section2-background'>
                    <div className="container">
                        
                        <div style={{ textAlign: 'left', paddingTop: 70, maxWidth: 420 }} className="section2-right goat-section-mobile">
                            <BadgeHome testo="REAL ESTATE" style={{ color: '#4F60D6' }} />
                            <h3 dangerouslySetInnerHTML={{ __html: testi('goatestate_h3') }} className="h3-section2"></h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goatestate_parag') }} style={{ fontSize: 20, lineHeight: '28px' }} className="parag-section2"></p>
                        </div>
                        <div style={{ paddingTop: 0 }} className="section2-left">
                            <img style={{ maxWidth: 590, position: 'absolute', top: -30 }} className='goat-estate-img' src="assets/img/HomeGoatEstate/panda-2@1x.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;