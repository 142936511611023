import React, { Component } from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import StatsTab from '../../../components/area_riservata/goatech/ui/StatsTab.js';
import GoatechBtnMobile from '../../../components/area_riservata/goatech/ui/BtnMobile';

import StatsValue from '../../../components/area_riservata/goatech/ui/StatsValue.js';
import { Reti } from '../../../components/wallet_generic/Wallets.js';

export class GoatechPEMMHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
        };
        this.setAddress = this.setAddress.bind(this);
    }
    setAddress(address) {
        this.setState({'menu_address': address});
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />
                
                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech' subMenu='pamm-history' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller-stylized scroller'>
                                    <GoatechBtnMobile />

                                    <h3>GOATECH PAMM History</h3>

                                    <div className='goatech-stats-cols'>
                                        <div className='goatech-stats-col60'>
                                            sdadsa
                                        </div>
                                        <div className='goatech-stats-col40'>
                                            <div className='goatech-stats-tabs'>
                                                <StatsTab titolo="Total PAMM Balance" valore="$ 1.560.000" />
                                                <StatsTab titolo="Last Week" valore="+ 160.000$" percentgreen="20" />
                                                <StatsTab titolo="Last 4 Weeks" valore="" percentred="20" />
                                                <StatsTab titolo="Total Gain PAMM" valore="" percentred="20" />
                                                <StatsTab titolo="Total Goatech Weeks" valore="30" />
                                                <StatsTab titolo="Actual Holders" valore="853" />
                                            </div>
                                        </div>
                                    </div>

                                    <h3>Last closed positions</h3>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table stats-table-left">
                                            <thead>
                                                <tr>
                                                    <th>Symbol</th>
                                                    <th>Enter price</th>
                                                    <th>Exit price</th>
                                                    <th>Enter Date</th>
                                                    <th>Exit Date</th>
                                                    <th>Gain %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                                <tr>
                                                    <th>EUR/USD</th>
                                                    <th>1.20</th>
                                                    <th>1.30</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th>01/01/2022 - 21:00</th>
                                                    <th><StatsValue valore={20}/></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatechPEMMHistory;