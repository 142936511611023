import developer from './abi/dev/contract.json';
// import production from './abi/production/contract.json';
import { isDev } from '../wallet_generic/helpers/isDev';

export function getAbiContract() {
    if (isDev()) {
        return developer;
    }
    // return production;
}

export function getAddressContract() {
    if (isDev()) {
        return "0x842530B13d00Dee1C8c9dbDaE2B6A55B8568D5Ab";
    }
    return "";
}

export function getGenesisBlock() {
    if (isDev()) {
        // https://www.bscscan.com/tx/0x8a832a66c83aa45a4be09c0ac2a3c596b334d0272f77e655ebc9ad35ff249422
        return 20943034;
    }
    // 
    return 0;
}

export default getAbiContract;
