
import React, { Component } from 'react';

import { showError } from '../../ui/ToastErrore';

function mintPage() {
    if (localStorage.getItem("wallet_connected")) {
        window.location.href = "/mint";
    } else {
        showError({ 'titolo': 'Connect Wallet', 'testo': 'Connect your wallet first' });
    }
}

export class GoatMintBtn extends Component {
    render() {
        return (
            <p className='box-discordbtn'>
                <a className='mintbtn-home' style={{cursor: 'pointer'}} onClick={() => mintPage()}>Mint a new GOAT!</a>
            </p>
        );
    }
}

export default GoatMintBtn;