
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div style={{ paddingTop: 120 }} id="utilities" className='container-fluid'>
                <div className='section2-background'>
                    <div  className="container">

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatToMars/Rectangle278.png'}) center center no-repeat
                            `
                        }} className='goat-to-mars-background' />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/HomeGoatToMars/Rectangle289.png'}) center center no-repeat
                            `
                        }} className='goat-to-mars-background' />
                        
                        <div style={{ paddingTop: 20 }} className="section2-left">
                            <BadgeHome testo="ACADEMY" style={{ color: '#EC4A13' }} />
                            <h3 dangerouslySetInnerHTML={{ __html: testi('goat_to_mars_h3') }} className="h3-section2"></h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goat_to_mars_parag') }} style={{ maxWidth: 460 }} className="parag-section2"></p>
                        </div>
                        <div className="section2-right">
                            <img style={{ maxWidth: 550 }} src="assets/img/HomeGoatToMars/rectangle-103@1x.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;