import { isDev } from "../../wallet_generic/helpers/isDev";

export function getNetworkParameters() {
    if(isDev()) {
        // Rinkeby
        return {
            nome: 'Ethereum Rinkeby',
            chainId: '0x4',
        };
    } else {
        // Main net
        return {
            nome: 'Ethereum Main Net',
            chainId: '0x1',
        };
    }
}