// Se presente
export async function getMerkleMint(address) {
    
    const whitelist_url = "https://goat-hay-api-ggy6xprbuq-uc.a.run.app/snapshots/goat-snapshot-mainnet.json";
    
    const dataProofWL = await fetch(whitelist_url).then(function (response) {
        return response.json();
    }).then(function (json) {
        const accountProof = json.proofs.find(function (item) {
            return item.address.toLowerCase() === address.toLowerCase();
        });
        if (accountProof) {
            return accountProof.proof;
        } else {
            return null;
        }
    }).catch(() => {
        return null;
    });
    return dataProofWL;
}