import React, { Component } from 'react';

import HeaderGoat from '../../../../components/ui/HeaderGoat';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';

import { StatsBadge, StatsBadgeEnum } from '../../../../components/area_riservata/goatech/ui/StatsBadge';
import StatsValue from '../../../../components/area_riservata/goatech/ui/StatsValue.js';

import GoatechBtnBurn from '../../../../components/area_riservata/goatech/ui/BtnBurn.js';
import { Reti } from '../../../../components/wallet_generic/Wallets';

export class AdminGoatechBurnArea extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            'menu_address': '',
        };
        this.setAddress = this.setAddress.bind(this);
    }
    setAddress(address) {
        this.setState({'menu_address': address});
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-burn-area' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Request Burn Area</h3>
                                    
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Fieno #</th>
                                                    <th className='cell-left'>Fieno Total Gain</th>
                                                    <th className='cell-left'>Buy Date</th>
                                                    <th>Status</th>
                                                    <th className='cell-left'>Request burn date</th>
                                                    <th className='cell-left'>Burn Date</th>
                                                    <th>Burn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th className='cell-left'>0001</th>
                                                    <th className='cell-left'><StatsValue valore={20.20}/></th>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th className='cell-left'><StatsBadge badge={StatsBadgeEnum.PENDING_DEPOSIT} /></th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'><GoatechBtnBurn /></th>
                                                </tr>
                                                <tr>
                                                    <th className='cell-left'>0001</th>
                                                    <th className='cell-left'><StatsValue valore={20.20}/></th>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th className='cell-left'><StatsBadge badge={StatsBadgeEnum.PENDING_DEPOSIT} /></th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'><GoatechBtnBurn /></th>
                                                </tr>
                                                <tr>
                                                    <th className='cell-left'>0001</th>
                                                    <th className='cell-left'><StatsValue valore={20.20}/></th>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th className='cell-left'><StatsBadge badge={StatsBadgeEnum.PENDING_DEPOSIT} /></th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'><GoatechBtnBurn /></th>
                                                </tr>
                                                <tr>
                                                    <th className='cell-left'>0001</th>
                                                    <th className='cell-left'><StatsValue valore={20.20}/></th>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th className='cell-left'><StatsBadge badge={StatsBadgeEnum.PENDING_DEPOSIT} /></th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'><GoatechBtnBurn /></th>
                                                </tr>
                                                <tr>
                                                    <th className='cell-left'>0001</th>
                                                    <th className='cell-left'><StatsValue valore={20.20}/></th>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th className='cell-left'><StatsBadge badge={StatsBadgeEnum.PENDING_DEPOSIT} /></th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'><GoatechBtnBurn /></th>
                                                </tr>
                                                <tr>
                                                    <th className='cell-left'>0001</th>
                                                    <th className='cell-left'><StatsValue valore={20.20}/></th>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th className='cell-left'><StatsBadge badge={StatsBadgeEnum.PENDING_DEPOSIT} /></th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'><GoatechBtnBurn /></th>
                                                </tr>
                                                <tr>
                                                    <th className='cell-left'>0001</th>
                                                    <th className='cell-left'><StatsValue valore={20.20}/></th>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th className='cell-left'><StatsBadge badge={StatsBadgeEnum.PENDING_DEPOSIT} /></th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'>-</th>
                                                    <th className='cell-left'><GoatechBtnBurn /></th>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminGoatechBurnArea;