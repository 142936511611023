import React, { Component } from 'react';

export class StatsTab extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div className='goatech-stats-tab'>
            <div className='stats-tab-box'>
                <p className='stats-tab-title'>{this.props.titolo}</p>
                <p className='stats-tab-valore'>
                    {this.props.valore}
                    {this.props.valore != "" ? (<span>&nbsp;&nbsp;</span>) : ""}
                    {this.props.percentgreen && this.props.percentgreen != "" ? (<span className='stats-tab-percentgreen'>(+{this.props.percentgreen}%)</span>) : ''}
                    {this.props.percentred && this.props.percentred != "" ? (<span className='stats-tab-percentred'>(-{this.props.percentred}%)</span>) : ''}
                </p>
            </div>
        </div>);
    }
}

export default StatsTab;