
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export class MenuArea extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            'address': '',
            'showAddress': false,
            'currentPage': '',
            'subMenuActive': '',
            'currentSubNavActive': '',
            'subMenuHidden': true,
        };
        
        this.state.address = props.address;
        this.state.currentPage = props.currentPage;
        this.state.currentSubNavActive = props.currentPage;
        if(props.subMenu) {
            this.state.subMenuActive = props.subMenu;
            this.state.subMenuHidden = false;
        }
        this.toggleShowAddress = this.toggleShowAddress.bind(this);
    }
    
    toggleShowAddress() {
        this.setState({
            'showAddress': !this.state.showAddress
        });
    }
    
    toggleAndActiveSubmenu(page) {
        if(!this.state.subMenuHidden && this.state.currentSubNavActive != page) {

        } else {
            this.setState({
                'subMenuHidden': !this.state.subMenuHidden,
            });
        }
        this.setState({
            'currentSubNavActive': page
        });
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.address != this.props.address) {
            this.setState({ address: this.props.address });
        }
    }

    render() {
        return (
            <>
                <div className='account-padding'>
                    <img className='goat-logo-white' src="assets/img/goat_nation_WHITE.png" />
                    <h2>Welcome to your<br />Reserved GOAT Area</h2>

                    <ul className='account-nav scroller-stylized'>
                        <li className={this.state.currentPage == 'home' ? 'nav-active' : ''} ><a href="/account"><FontAwesomeIcon icon={faCircle} /> Home</a></li>
                        <li className={this.state.currentPage == 'goatech' ? 'nav-active' : ''}>
                            <a onClick={() => this.toggleAndActiveSubmenu('goatech')} ><FontAwesomeIcon icon={faCircle} /> GOATECH</a>
                            
                            <ul style={{height: 69}} className={(!this.state.subMenuHidden && (this.state.currentSubNavActive == 'goatech') ? 'subnav-active ' : '') + 'subnav-ul subnav-ul-3n'}>
                                <li><a className={this.state.subMenuActive == 'mint-fieno' ? 'subnav-active' : ''} href="/goatech-mint-fieno">Buy Fieno</a></li>
                                <li><a className={this.state.subMenuActive == 'stats' ? 'subnav-active' : ''} href="/goatech-stats">My Goatech Stats</a></li>
                                {/* <li><a className={this.state.subMenuActive == 'pamm-history' ? 'subnav-active' : ''} href="/goatech-pamm-history">Goatech PAMM History</a></li> */}
                            </ul>
                        </li>
                        <li className={'nav-tba'}><a href="#"><FontAwesomeIcon icon={faCircle} /> Staking (soon)</a></li>
                        <li className={'nav-tba'}><a href="#"><FontAwesomeIcon icon={faCircle} /> Breeding (soon)</a></li>
                        <li className={'nav-tba'}><a href="#"><FontAwesomeIcon icon={faCircle} /> GOATREX (soon)</a></li>
                        
                        {/*
                        <li className={'nav-admin' + (this.state.currentPage == 'goatech-admin' ? ' nav-active' : '')}>
                            <a onClick={() => this.toggleAndActiveSubmenu('goatech-admin')}><FontAwesomeIcon icon={faCircle} /> ADMIN AREA</a>

                            <ul className={(!this.state.subMenuHidden && (this.state.currentSubNavActive == 'goatech-admin') ? 'subnav-active ' : '') + 'subnav-ul subnav-ul-2n'}>
                                <li><a className={this.state.subMenuActive == 'admin-burn-area' ? 'subnav-active' : ''} href="/admin-burn-area">Request Burn Area</a></li>
                                <li><a className={this.state.subMenuActive == 'admin-update-history' ? 'subnav-active' : ''} href="/admin-update-history">Update History</a></li>
                            </ul>
                        </li>
                        */}
                    </ul>

                    <div className='account-address-box'>
                        <a style={{ cursor: 'pointer' }} onClick={() => this.toggleShowAddress()}>
                            {!this.state.showAddress ? <FontAwesomeIcon icon={faEye} /> : ''}
                            {this.state.showAddress ? <FontAwesomeIcon icon={faEyeSlash} /> : ''}
                        </a>
                        Wallet Address:
                        <span>{this.state.showAddress ? this.state.address : '0x0************'}</span>
                    </div>
                </div>
                <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/FILIPPO_BGSCURO.jpg'})` }} className='account-col1-bg'></div>
            </>
        );
    }
}

export default MenuArea;