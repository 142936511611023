
import React, { Component } from 'react';

import DiscordBtn from '../ui/DiscordBtn.js';
import GoatMintBtn from '../ui/GoatMintBtn.js';

import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div style={{
                    background: `url(${process.env.PUBLIC_URL + '/assets/img/section1/27356-earth-animation.png'})
                    `
                }} className='section1-background goat-fromday0'>
                    <div style={{
                        background: `url(${process.env.PUBLIC_URL + '/assets/img/section1/ELEMENTI.png'})
                            `
                    }} className='goat-fromday0-decoration' >
                        <div className="container">
                            <div className="section1-right">
                                <h3 dangerouslySetInnerHTML={{ __html: testi('fromday0_h3') }}></h3>
                                <p dangerouslySetInnerHTML={{ __html: testi('fromday0_parag') }} className="parag-section1"></p>
                                <GoatMintBtn />
                                <DiscordBtn />
                            </div>
                            <div className="section1-left">
                                <img src="assets/img/section1/di1.png?v=3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default HomeSection;