
import { getNetworkParameters as getNetworkParametersEthereum } from "../../ethereum/helpers/getNetworkParameters";
import { getNetworkParameters as getNetworkParametersBinance } from "../../binance/helpers/getNetworkParameters";

import { Reti } from "../Wallets";

export function getChainParameters(rete) {
    if (rete == Reti.ETHEREUM) {
        return getNetworkParametersEthereum();
    } else if (rete == Reti.BINANCE) {
        return getNetworkParametersBinance();
    }
}