
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div className='section2-background'>
                    <div className="container">
                        <div style={{ paddingTop: 80 }} className="section2-right goat-section-mobileai">
                            <BadgeHome testo="CRYPTO" style={{ color: '#E5A433' }} />
                            <h3 dangerouslySetInnerHTML={{ __html: testi('goatech_h3') }} className="h3-section2"></h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goatech_parag') }} style={{ fontSize: 20, lineHeight: '28px' }} className="parag-section2"></p>
                        </div>
                        <div style={{ paddingTop: 0 }} className="goat-ai-img-section section2-left">
                            <img className='goat-ai-img' style={{ maxWidth: 530, position: 'absolute', top: -85 }} src="assets/img/HomeGoatAI/15-king-goat-color-correction-png-1@1x.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;