import React, { Component } from 'react';

import { ContractData } from '../../components/ethereum/getContractData';

import HeaderGoat from '../../components/ui/HeaderGoat.js';
import Menu from '../../components/area_riservata/ui/Menu.js';

import { getMintedNFT } from '../../components/ethereum/getMintedNFT';
import SlickWalletNFT from '../../components/area_riservata/ui/SlickWalletNFT.js';

import moment from 'moment';
import 'moment-timezone';

export class AreaRiservata extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'address': '',
            'infoLoaded': false,
            'listNFT': {},
            'dailyRoi': 0,
            'toWithdraw': 0
        };

        this.setAddress = this.setAddress.bind(this);
    }

    setAddress(address) {
        this.setState({
            address: address
        });

        // Carico le informazioni del contratto
        ContractData.loadInfo(address).then(result => {

            this.setState({
                ...result,
                dailyRoi: (4 * result.balanceOf)
            });
            
            // Verifico gli NFT
            if (result.balanceOf > 0) {
                getMintedNFT(address, result.balanceOf).then(list_nft => {
                    this.setState({
                        'listNFT': list_nft,
                        'infoLoaded': true
                    });

                    // Differenza in giorni dal giorno di mint.
                    // Per ogni giorno devo dare 4 token
                    let oggi = moment().tz("Europe/Rome");
                    
                    let count = 0;
                    let giorni_diff = 0;
                    list_nft.map((nft, i) => {
                        giorni_diff = oggi.diff(nft.data, 'days');
                        count += (4 * giorni_diff);
                    });
                    
                    this.setState({ toWithdraw: count.toLocaleString() });
                });
            } else {
                this.setState({
                    'infoLoaded': true
                });
            }
        });
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <Menu currentPage='home' address={this.state.address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding'>
                                    <p className='badge-claim-intest'><span className='badge-claim'>CLAIM&nbsp;&nbsp;&nbsp;$LATTE</span></p>

                                    <div className='account-grid'>
                                        <div className='account-grid-col'>
                                            $LATTE to Claim:
                                            {this.state.infoLoaded ?
                                                <span className='badge-latte-toclaim'>{this.state.toWithdraw}</span> :
                                                <span className='badge-latte-loading gradient-loader'></span>
                                            }
                                        </div>
                                        <div className='account-grid-col'>
                                            $LATTE on Wallet:
                                            {this.state.infoLoaded ?
                                                <span className='badge-latte-wallet'>0</span> :
                                                <span className='badge-latte-loading gradient-loader'></span>
                                            }
                                        </div>
                                    </div>

                                    <div className='account-grid'>
                                        <div className='account-grid-col'>
                                            Your daily $LATTE production:
                                            {this.state.infoLoaded ?
                                                <span className='badge-latte-power'>{this.state.dailyRoi} $LATTE/DAY</span> :
                                                <span style={{ maxWidth: 200 }} className='badge-latte-loading gradient-loader'></span>
                                            }
                                        </div>
                                    </div>

                                    <div className='account-grid account-grid-nftwallet' >
                                        <div className='account-grid-col'>
                                            Your GOATs on wallet:
                                        </div>
                                    </div>

                                    <div className='container-sliderWalletNft'>
                                        <SlickWalletNFT loaded={this.state.infoLoaded} listNFT={this.state.listNFT} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AreaRiservata;