
import { ethers } from "ethers";
import { getAbiContract, getAddressContract } from './getContractInfo';

import { isDev } from '../wallet_generic/helpers/isDev';
import { getCurrentChain } from '../wallet_generic/helpers/getCurrentChain';
import { isChainCorrect } from "../wallet_generic/helpers/isChainCorrect";

import { getSelectedProvider } from '../wallet_generic/helpers/getProvider';
import { ChangeChainRedirect } from '../wallet_generic/helpers/changeChainRedirect';

import { getInPrivateWLData, getInWLData } from './getContractWhitelist'

import { FasiMintEnum, FasiMint } from './getContractData'

import { showError } from '../ui/ToastErrore';
import { Reti } from "../wallet_generic/Wallets";

const handleError = (error) => {
    if (error.includes("code=INSUFFICIENT_FUNDS")) {
        return 'Insufficent funds to complete the transaction';
    } else if (error.includes("max-mint-limit")) {
        return 'You have reached the personal Goat minting limit.';
    } else if (error.includes("max-supply-reached")) {
        return 'Sold out: all Goats have been minted.';
    } else if (error.includes("incorrect-ether-value")) {
        return 'Invalid payment. Incorrect paid value.';
    } else if (error.includes("invalid-proof")) {
        return 'Your address is not whitelisted.';
    } else {
        return 'An error occurred, try again';
    }
};

export class GoatMintExecute {
    
    static async runMint(data_mint, call) {

        if (!data_mint.address || data_mint.address == ""
            || !data_mint.mintQnt || data_mint.mintQnt == "") {
            return false;
        }

        const address = data_mint.address;
        const mintQnt = data_mint.mintQnt;
        
        const connector = getSelectedProvider();
        
        // Controllo la rete connessa
        const _chainId = await getCurrentChain(connector);
        if (!isChainCorrect(_chainId, Reti.ETHEREUM)) {
            ChangeChainRedirect(Reti.ETHEREUM);
            return false;
        }
        
        // Prendo le info del contratto
        const contractAbi = getAbiContract();
        const contractAddress = getAddressContract();

        const provider = new ethers.providers.Web3Provider(connector);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractAbi, signer);

        // Costo del mint
        let mintPrice = await contract.getCurrentMintPrice();
        mintPrice = mintPrice.mul(mintQnt)._hex;

        // Ricontrollo le disponibilità supply
        const totalSupply = parseInt(Number(await contract.totalSupply()));
        const maxSupply = parseInt(Number(await contract.MAX_SUPPLY()));
        if ((totalSupply + mintQnt) > maxSupply) {
            showError({ 'titolo': 'Sold out', 'testo': 'Goat shown not available for mint.' });
            return false;
        }
        
        // Ricontrollo le disponibilità max per wallet
        const maxQntPerWallet = parseInt(Number(await contract.MAX_MINT_PER_WALLET()));
        const mintedNft = parseInt(Number(await contract.mintsPerAddress(address)));
        if ((mintedNft + mintQnt) > maxQntPerWallet) {
            showError({ 'titolo': 'Reached Goat limit', 'testo': 'You have reached the personal Goat minting limit.' });
            if (!isDev()) {
                return false;
            }
        }

        // Fase del mint
        let faseMint = await contract.phase();
        faseMint = FasiMintEnum[faseMint] ? FasiMintEnum[faseMint] : '';

        try {
            let mintMode = null;

            // Se è nella whitelist privata (sempre aperta e free)
            const proofPrivateWL = await getInPrivateWLData(address);
            if (proofPrivateWL) {
                // Gratis 
                mintMode = await contract.mintPrivate(mintQnt, proofPrivateWL, { value: 0 });
            } else {

                // Verifico le whitelist
                if (faseMint == FasiMint.PRIVATE) {
                    showError({ 'titolo': 'Mint not enabled', 'testo': 'You are not in the current whitelist' });
                    return false;

                } else if (faseMint == FasiMint.WHITELIST) {
                    const proofWL = await getInWLData(address);
                    if (!proofWL) {
                        showError({ 'titolo': 'Mint not enabled', 'testo': 'You are not in the current whitelist' });
                        return false;
                    }
                    mintMode = await contract.mintWhitelist(mintQnt, proofWL, { value: mintPrice });

                } else if (faseMint == FasiMint.PUBLIC) {

                    mintMode = await contract.mintPublic(mintQnt, { value: mintPrice });
                }
            }
            if (mintMode == null) {
                return 'Invalid mint mode';
            }

            
            call.setWaitingTransactionText('Transaction pending confirmation on the network....');

            const receipt = await mintMode.wait().then((res) => {
                return true;
            }, error => {
                return 'Error in transaction verification ' + (error.message ? error.message : '');
            }).catch((error) => {
                return 'Error in transaction verification ' + (error.message ? error.message : '');
            });
            
            return receipt;

        } catch (error) {
            if (!error.message) {
                return handleError('');
            }
            return handleError(error.message);
        }
    }
}