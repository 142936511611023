import React, { Component } from 'react';

import HeaderGoat from '../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../components/area_riservata/ui/Menu.js';

import StatsTab from '../../../components/area_riservata/goatech/ui/StatsTab.js';
import { StatsBadge, StatsBadgeEnum } from '../../../components/area_riservata/goatech/ui/StatsBadge';
import StatsValue from '../../../components/area_riservata/goatech/ui/StatsValue.js';

import GoatechBtnBurn from '../../../components/area_riservata/goatech/ui/BtnBurn';
import GoatechBtnMobile from '../../../components/area_riservata/goatech/ui/BtnMobile';
import { Reti } from '../../../components/wallet_generic/Wallets.js';
import { EndPointMethod, RequestBackend } from '../../../components/area_riservata/backend/RequestBackend.js';

export class GoatechStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'menu_address': '',
            'fieno_history': {}
        };
        this.setAddress = this.setAddress.bind(this);
    }
    async setAddress(address) {
        this.setState({ 'menu_address': address });

        const request = new RequestBackend(address, EndPointMethod.GOATECH_STATS);
        const response = await request.run();
        if (request.getStatusCode() == 200) {
            this.setState({ 'fieno_history': response.response });
        }
    }

    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech' subMenu='stats' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>
                                    <GoatechBtnMobile />

                                    <h3>My GOATECH Stats</h3>

                                    <div className='goatech-stats-cols'>
                                        <div className='goatech-stats-col1'>
                                            <div className='goatech-stats-tabs'>
                                                {/* <StatsTab titolo="My PAMM Balance" valore="$ 100.000.000" />
                                                <StatsTab titolo="My Last Week Profits" valore="+20.000$" percentred="10" /> { percentgreen="20" */}
                                                <StatsTab titolo="My Fieno quantity" valore={this.state.fieno_history ? this.state.fieno_history.length : ''} />
                                                <StatsTab titolo="My Withdraw Profits" valore="+0.000$" />
                                            </div>
                                        </div>
                                        <div className='goatech-stats-col1' style={{ position: 'relative' }}>
                                            <div
                                                style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goatech/FarmHouseBarn1.png'})` }}
                                                className='goatech-stats-farmhousebarn'>
                                            </div>
                                            <div
                                                style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goatech/gt212.png'})` }}
                                                className='goatech-stats-gt212'>
                                            </div>
                                        </div>
                                    </div>

                                    <h3>My Withdrawal History</h3>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Date</th>
                                                    <th>Gain %</th>
                                                    <th>Week number</th>
                                                    <th>Quantity</th>
                                                    <th className='cell-left'>Transaction Link</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th colSpan="5" className='cell-left'>Nothing yet to show</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>My Goatech Wallet</h3>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Fieno #</th>
                                                    <th className='cell-left'>Fieno Total Gain</th>
                                                    <th className='cell-left'>Buy Date</th>
                                                    <th className='cell-left'>Status</th>
                                                    <th className='cell-left'>Request burn date</th>
                                                    <th className='cell-left'>Burn Date</th>
                                                    <th className='cell-left'>Burn</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.fieno_history ? Array.from(this.state.fieno_history).map((balla, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th className='cell-left'>{balla.id}</th>
                                                            <th className='cell-left'><StatsValue valore={0} /></th>
                                                            <th className='cell-left'>{balla.buyDate}</th>
                                                            <th className='cell-left'><StatsBadge badge={StatsBadgeEnum[balla.state]} /></th>
                                                            <th className='cell-left'>-</th>
                                                            <th className='cell-left'>-</th>
                                                            <th className='cell-left'><GoatechBtnBurn disabled /></th>
                                                        </tr>
                                                    )
                                                }) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoatechStats;