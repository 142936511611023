import { ethers } from "ethers";

import { getAddressContract, getAbiContract } from "../getContractInfo";
import { getSelectedProvider } from "../../wallet_generic/helpers/getProvider";
import { getCurrentChain } from "../../wallet_generic/helpers/getCurrentChain";
import { isChainCorrect } from "../../wallet_generic/helpers/isChainCorrect";
import { ChangeChainRedirect } from '../../wallet_generic/helpers/changeChainRedirect';
import { Reti } from "../../wallet_generic/Wallets";

export async function getWeb3Provider() {
    let connector = getSelectedProvider();
    
    // Controllo la rete connessa
    const _chainId = await getCurrentChain(connector);
    if (!isChainCorrect(_chainId, Reti.BINANCE)) {
        ChangeChainRedirect(Reti.BINANCE);
        return false;
    }
    
    const contractAbi = getAbiContract();
    const contractAddress = getAddressContract();
    
    const provider = new ethers.providers.Web3Provider(connector);
    const contract = new ethers.Contract(contractAddress, contractAbi, provider);
    
    return { contract, provider };
}