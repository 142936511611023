import React, { Component } from 'react';

export class StatsValue extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<span className={this.props.valore >= 0 ? 'stats-green' : 'stats-red'} >{this.props.valore}%</span>);
    }
}

export default StatsValue;