
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

import { Reti, Wallets } from '../wallet_generic/Wallets.js';
import { ConnectorWallet } from '../wallet_generic/ConnectorWallet.js';

export class ModalConnectWallet extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            mostra_modal: false
        };
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.impostaModal != this.props.impostaModal) {
            this.setState({ mostra_modal: this.props.impostaModal });
        }
    }

    render() {
        return (
            <div className={'modal-overlay' + (this.state.mostra_modal ? ' modal-show' : '')} >
                <div className='modal'>
                    <a onClick={() => this.props.toggleModalWallet()} className='modal-close'><FontAwesomeIcon icon={faClose} /></a>

                    <h4>Connect your wallet</h4>
                    <div className='modal-select-wallets'>
                        <div onClick={() => new ConnectorWallet(Wallets.METAMASK, Reti.ETHEREUM).connect()} className='wallet-obj'>
                            <img src="assets/img/wallets/metamask.png" />
                            <p className='wallet-obj-title'>Metamask</p>
                            <p className='wallet-obj-descr'>Connect your Metamask wallet</p>
                        </div>
                        <div onClick={() => new ConnectorWallet(Wallets.COINBASE, Reti.ETHEREUM).connect()} className='wallet-obj'>
                            <img src="assets/img/wallets/coinbase.png" />
                            <p className='wallet-obj-title'>Coinbase</p>
                            <p className='wallet-obj-descr'>Connect your Coinbase wallet</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalConnectWallet;