import { isDev } from "../../wallet_generic/helpers/isDev";

export function getNetworkParameters() {
    if (isDev()) {
        // Binance test
        return {
            nome: 'Binance Smart Chain - Testnet',
            chainName: 'Smart Chain - Testnet',
            chainId: '0x61', // 97
            rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
            blockExplorerUrls: ['https://testnet.bscscan.com'],
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            }
        };
    } else {
        // Binance prod
        return {
            nome: 'Binance Smart Chain',
            chainName: 'Smart Chain',
            chainId: '0x38', // 56
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com'],
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            }
        };
    }
}