import { isDev } from '../../wallet_generic/helpers/isDev';

export function getBackendURI() {
    if (isDev()) {
        const hostname = window.location.hostname;
        if (hostname === "goat.botb.it" || hostname === "goat2.botb.it" || hostname == "goat-nation-ui-7o6.pages.dev") {
            return "https://goat-nation-node-backend-ic5mzslena-oc.a.run.app";
        }
        return "http://localhost:8080";
    } else {
        return "https://goat-nation-node-backend-ic5mzslena-oc.a.run.app";
    }
}