
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faLock } from '@fortawesome/free-solid-svg-icons'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'

import DiscordBtn from '../ui/DiscordBtn.js';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div className='section2-background goat-nation'>
                    <div id="goat-nation" className="container" style={{ position: 'relative' }}>
                        <div className="section2-left">
                            <h3 dangerouslySetInnerHTML={{ __html: testi('goatnation_h3') }} className="h3-section2 mobile_center"></h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goatnation_parag') }} style={{ fontSize: 22 }} className="parag-section2 mobile_center"></p>
                            <ul className="list-section2">
                                <li><FontAwesomeIcon icon={faCalendar} /> MINT DATE: 26/04/2022 </li>
                                <li><FontAwesomeIcon icon={faLock} /> SUPPLY: 4,444</li>
                                <li><FontAwesomeIcon icon={faEthereum} /> PRICE: $1.000 (ETH actual price)</li>
                            </ul>

                            <DiscordBtn />
                        </div>
                        <div className="section2-right">
                            <img style={{ maxWidth: 650, position: 'absolute', top: 40, right: 0 }} src="assets/img/section2/filippo.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;