import { getWeb3Provider } from './helpers/getWeb3Provider';

export const FasiMintEnum = {
    0: 'PRIVATE',
    1: 'WHITELIST',
    2: 'PUBLIC'
};

export const FasiMint = {
    PRIVATE: 'PRIVATE',
    WHITELIST: 'WHITELIST',
    PUBLIC: 'PUBLIC'
};

export class ContractData {

    static async loadInfo(address) {

        const provider = await getWeb3Provider();
        const contract = provider.contract;

        // Totali comprati attualmente
        let totalSupply = await contract.totalSupply();
        totalSupply = parseInt(Number(totalSupply));

        // Disponibilità massima
        let maxSupply = await contract.MAX_SUPPLY();
        maxSupply = parseInt(Number(maxSupply));

        // Quantità massima per wallet
        let maxQntPerWallet = await contract.MAX_MINT_PER_WALLET();
        maxQntPerWallet = parseInt(Number(maxQntPerWallet));

        // Prendo le quantità già comprate per il wallet (se ci sono)
        let mintedNft = await contract.mintsPerAddress(address);
        mintedNft = parseInt(Number(mintedNft));

        // Prendo le quantità ora presenti nel wallet dell'address
        let balanceOf = await contract.balanceOf(address);
        balanceOf = parseInt(Number(balanceOf));

        // Fase del mint
        let faseMint = await contract.phase();
        faseMint = FasiMintEnum[faseMint] ? FasiMintEnum[faseMint] : '';

        // Prezzo del mint
        let mintPrice = await contract.getCurrentMintPrice();

        return {
            'faseMint': faseMint,
            'soldOut': (maxSupply - totalSupply <= 0 ? true : false),
            'totalSupply': totalSupply,
            'maxSupply': maxSupply,
            'mintPrice': mintPrice.toString(),
            'maxQnt': maxQntPerWallet,
            'mintedNft': mintedNft,
            'balanceOf': balanceOf,
            'maxQntPerWallet': (maxQntPerWallet - mintedNft),
        };
    }
}

export default ContractData;