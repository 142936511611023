
import React, { Component } from 'react';

export class BadgeHome extends Component {
    render() {
        return (
            <div className='badge-goat' style={this.props.style}>
                {this.props.testo}
            </div>
        );
    }
}

export default BadgeHome;