
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'

export class DiscordBtn extends Component {
    render() {
        return (
            <p className='box-discordbtn'>
                <a target="_blank" href="https://discord.gg/goatnation">Join Discord&nbsp;&nbsp;<FontAwesomeIcon icon={faDiscord} /></a>
            </p>
        );
    }
}

export default DiscordBtn;