
import React, { Component } from 'react';

import BadgeHome from '../ui/BadgeHome.js';
import { testi } from "../../../language/lang.js";

export class HomeSection extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <div className='section2-background section-goatrex-background'>
                    <div className="container" style={{ position: 'relative' }}>
                        <div style={{ paddingTop: 110, maxWidth: 430 }} className="section2-left goat-section-mobileai">
                            <BadgeHome testo="FOREX" style={{color: '#8FFF00'}} />
                            <h3 dangerouslySetInnerHTML={{ __html: testi('goatrex_h3') }} className="h3-section2"></h3>
                            <p dangerouslySetInnerHTML={{ __html: testi('goatrex_parag') }} style={{ fontSize: 22 }} className="parag-section2"></p>
                        </div>
                        <div className="section2-right goatrex-mobile">
                            <img style={{ maxWidth: 590, position: 'absolute', top: -30, right: 0 }} src="assets/img/HomeGOATREX/GOAT_07.png" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSection;