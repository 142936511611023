
import React, { Component } from 'react';

import { testi } from "../../../language/lang.js";

export class HomeFoto extends Component {
    render() {
        return (
            <div id="index" className='homepage-intest-photo'>
                <div className='hp-intest-photodesktop'>
                    <div className='container-fluid'>

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% + 560px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% + 500px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% + 440px) 170px no-repeat,

                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% - 485px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% - 420px) 170px no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/bandiera-header.png'}) calc(50% - 360px) 170px no-repeat
                            `
                        }} className='intest-bandiere' />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/shadow-3-2@1x.png'}) calc(50% + 550px) bottom no-repeat, 
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/shadow-3-3@1x.png'}) calc(50% - 550px) bottom no-repeat
                            `
                        }} className="intest-shadow" />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/832-8326155-fog-1@1x.png'}) left bottom no-repeat,
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/832-8326155-fog-2@1x.png'}) right bottom no-repeat 
                            `
                        }} className="intest-fog-image" />

                        <div style={{
                            background: `
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/lorenzo-1@1x.png'}) calc(50% + 260px) 60px no-repeat, 
                            url(${process.env.PUBLIC_URL + '/assets/img/fotohome/marco-png-1@1x.png'}) calc(50% - 260px) 60px no-repeat
                        `
                        }} className="intest-goats" />

                        <div style={{
                            background: `url(${process.env.PUBLIC_URL + '/assets/img/fotohome/omar-front-1@1x.png'}) center 30px no-repeat`,
                        }} className='intest-goat-omar' />

                        <div className='intest-fog' />
                    </div>
                    <h1 className='intest-h1' dangerouslySetInnerHTML={{ __html: testi('welcome_h1') }} ></h1>
                </div>
                <div className='hp-intest-photomobile'>
                    <img src="assets/img/fotohome/fotohome_mobile.png" />
                </div>
            </div>
        );
    }
}

export default HomeFoto;