
import { getCorrectChain } from './helpers/getCorrectChain';

// Se presente nella whitelist privata
export async function getInPrivateWLData(address) {
    
    const whitelist_url = "https://goatnation-ggy6xprbuq-uc.a.run.app/" + getCorrectChain() + "/phase-0.json";

    const dataProofWL = await fetch(whitelist_url).then(function (response) {
        return response.json();
    }).then(function (json) {
        const accountProof = json.proofs.find(function (item) {
            return item.address.toLowerCase() === address.toLowerCase();
        });
        if (accountProof) {
            return accountProof.proof;
        } else {
            return null;
        }
    }).catch(() => {
        return null;
    });
    return dataProofWL;
}


// Se presente nella whitelist fase 1
export async function getInWLData(address) {

    const whitelist_url = "https://goatnation-ggy6xprbuq-uc.a.run.app/" + getCorrectChain() + "/phase-1.json";

    const dataProofWL = await fetch(whitelist_url).then(function (response) {
        return response.json();
    }).then(function (json) {
        const accountProof = json.proofs.find(function (item) {
            return item.address.toLowerCase() === address.toLowerCase();
        });
        if (accountProof) {
            return accountProof.proof;
        } else {
            return null;
        }
    }).catch(() => {
        return null;
    });
    return dataProofWL;
}