
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import './App.css';
import './App_mobile.css';

import Home from './pages/Home.js';
import Mint from './pages/MintGoat.js';
import AreaRiservata from './pages/area_riservata/AreaRiservata.js';
import SwitchNetwork from './pages/SwitchNetwork';
import GoatechMintFieno from './pages/area_riservata/goatech/GoatechMintFieno.js';
import GoatechStats from './pages/area_riservata/goatech/GoatechStats.js';
import GoatechPEMMHistory from './pages/area_riservata/goatech/GoatechPEMMHistory.js';
import GoatechVerifyAccount from './pages/area_riservata/goatech/GoatechVerifyAccount.js';

import AdminGoatechBurnArea from './pages/area_riservata/goatech/admin/AdminGoatechBurnArea';
import AdminGoatechUpdateHistory from './pages/area_riservata/goatech/admin/AdminGoatechUpdateHistory';
import { Reti } from './components/wallet_generic/Wallets';

function Main() {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/mint' element={<Mint />}></Route>
      <Route path='/account' element={<AreaRiservata />}></Route>
      
      <Route path='/switch-network-ethereum' element={<SwitchNetwork goto={Reti.ETHEREUM} />}></Route>
      <Route path='/switch-network-binance' element={<SwitchNetwork goto={Reti.BINANCE} />}></Route>
      
      <Route path='/verify-wallet' element={<GoatechVerifyAccount />}></Route>
      <Route path='/goatech-mint-fieno' element={<GoatechMintFieno />}></Route>
      <Route path='/goatech-stats' element={<GoatechStats />}></Route>
      <Route path='/goatech-pamm-history' element={<GoatechPEMMHistory />} ></Route>

      <Route path='/admin-burn-area' element={<AdminGoatechBurnArea />} ></Route>
      <Route path='/admin-update-history' element={<AdminGoatechUpdateHistory />} ></Route>
    </Routes>
  );
}

export default Main;