import React, { Component } from 'react';

import HeaderGoat from '../../../../components/ui/HeaderGoat.js';
import MenuArea from '../../../../components/area_riservata/ui/Menu.js';

import StatsValue from '../../../../components/area_riservata/goatech/ui/StatsValue.js';

import ModalAdminInsertTrans from '../../../../components/area_riservata/goatech/ui/ModalAdminInsertTrans.js';
import { Reti } from '../../../../components/wallet_generic/Wallets.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export class AdminGoatechUpdateHistory extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            'menu_address': '',
            'modal_insert_trans': false
        };
        
        this.setAddress = this.setAddress.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }
    setAddress(address) {
        this.setState({'menu_address': address});
    }
    toggleModal() {
        this.setState({ modal_insert_trans: !this.state.modal_insert_trans });
    }
    
    render() {
        return (
            <div className="account">
                <HeaderGoat className="header-black" rete={Reti.BINANCE} setAddress={this.setAddress} checkLogged="true" />

                <div className='container-top'>
                    <div className='container-account'>
                        <div className='account-columns'>
                            <div className='account-col1'>
                                <MenuArea currentPage='goatech-admin' subMenu='admin-update-history' address={this.state.menu_address} />
                            </div>
                            <div className='account-col2'>
                                <div className='account-padding goatech-stats scroller scroller-stylized'>

                                    <h3>Update History</h3>
                                    
                                    <form className='form-trans-update'>
                                        <p>Transaction Link:</p>
            
                                        <input type={'text'} placeholder="Insert here the link" />
                                        <input type={'submit'} value="SUBMIT NEW WEEK STATS" />
                                        <span style={{marginLeft: 20, marginRight: 20, color: '#ddd'}}>|</span>
                                        <a className='btn-block-orange' style={{fontSize: 16, fontWeight: 500}} onClick={() => this.toggleModal()}><FontAwesomeIcon icon={faPlus} /> Manuale</a>
                                    </form>
                                    <ModalAdminInsertTrans toggleModal={this.toggleModal} impostaModal={this.state.modal_insert_trans} />

                                    <h3>Withdrawal Profit History</h3>
                                    <div className='goatech-stats-table-wrapper'>
                                        <table className="goatech-stats-table">
                                            <thead>
                                                <tr>
                                                    <th className='cell-left'>Date</th>
                                                    <th>Gain %</th>
                                                    <th>Week number</th>
                                                    <th>Quantity</th>
                                                    <th className='cell-left'>Transaction Link</th>
                                                    { /* <th style={{width: 80}}></th> */ }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th className='cell-left'>01/01/2022</th>
                                                    <th><StatsValue valore={20.20} /></th>
                                                    <th>6</th>
                                                    <th>1200 USDC</th>
                                                    <th className='cell-left'>https://etherscan.com/1234</th>
                                                    { /* <th><FontAwesomeIcon icon={faTimesCircle} /></th> */ }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ background: `url(${process.env.PUBLIC_URL + '/assets/img/account/goat_nation_WHITE.png'}) no-repeat scroll right bottom -80px / contain` }} className='account-logo-goat'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminGoatechUpdateHistory;